export default {
    existingUploadedFiles: [],
    existingUploadedOwners: [],
    existingUploadedBranches: [],
    existingUploadedBod: [],
    salesProspects: [],
    existingBSP:[],
    spNumber: '',
    genericRule: [],
    bspRule:[],
    checkboxRule: [],
    scoreCardRule: [],
    comment: '',
    emailRule: [],
    fileRule: [],
    existingGenericRule: [],
    existingEmailRule: [],
    existingFileRUle: [],
    renewableUploadedFiles: [],
    renewableUploadedBranch: [],
    renewableUploadedOwnerAmla: [],
    salesProspectingForm: {
        id: '',
        account_name: '',
        legal_entity_name: '',
        trade_name: '',
        bp_idno: null,
        scorecard: '',
        date_presentation: '',
        documents_presented: [],
        summary_presentation: '',
        accept_or_negotiate: '',
        scorecard_result: ''   
    },
    salesProspectingFormExisting: {
        id: '',
        account_name: '',
        legal_entity_name: '',
        trade_name: '',
        bp_idno: null,
        date_presentation: '',
        documents_presented: [],
        summary_presentation: '',
        accept_or_negotiate: '',
    },
    existingInitialUploaded: {
        upload_picture: null,
        upload_supp_docs: null,
        profile_picture: null,
        upload_loi: null
    },
    initialUploaded: {
        upload_picture: null,
        upload_supp_docs: null,
        profile_picture: null,
        upload_loi: null
    },
    branches: {
        branch_name: '',
        is_draft: '',
        location_name: '',
        address_line_1: '',
        address_line_2: '',
        city: '',
        region: '',
        country: 'Philippines',
        province:'',
        barangay: '',
        postal_code: '',
        email_address: '',
        landline: '',
        cellphone: '',
        handler_name: '',
        handler_email: '',
        tid: '',
        ftid: '',
        apz: '',
        api: '',
        fla_user_access:'',
        location_code: null,
        class_code_branch: '',
        justification: {
            file: null
        },
        coordinates: {
            lat: '',
            lng: ''
        },
        business_permit: {
            file: null,
            date_issued: '',
            date_expiry: '',
            menuIssued: false,
            menuExpiry: false 
        },
        operating_hours: [
            {day:'MON', timeStart: '', timeEnd: '', isClosed: false}, 
            {day:'TUE', timeStart: '', timeEnd: '', isClosed: false}, 
            {day:'WED', timeStart: '', timeEnd: '', isClosed: false}, 
            {day:'THU', timeStart: '', timeEnd: '', isClosed: false}, 
            {day:'FRI', timeStart: '', timeEnd: '', isClosed: false},
            {day:'SAT', timeStart: '', timeEnd: '', isClosed: false},
            {day:'SUN', timeStart: '', timeEnd: '', isClosed: false},
        ],
        fla: [
            {
                first_name: '',
                middle_name: '',
                last_name: '',
                suffix: '',
                wupos_op_id: '',
                usp_op_id: '',
                fla_status:'',
                agent_code:'',
                amla: {
                    file: null,
                    date_issued: '',
                    date_expiry: '',
                    menuIssued: false,
                    menuExpiry: false
                },
                nbi: {
                    file: null,
                },
                email_address: '',
                citizenship: 'Filipino',
                date_of_seminar: '',
                tin_no: '',
                tel_no: '',
                fax_no: '',
                is_wupos: null,
                is_usp: null,
                menuExpiry: false
            }
        ],
        is_same_primary: null,
        mailing_address_line_1: '',
        mailing_address_line_2: '',
        mailing_country: 'Philippines',
        mailing_city: '',
        mailing_province: '',
        mailing_postal_code: '',
        mailing_region: '',
        mailing_landline: '',
        mailing_cellphone: '',
        mailing_email_address: ''
    },
    single_proprietor_upload: false,
    documents: {
        is_western_union: null,
        is_avp: null,
        is_nonex: null,
        single_proprietor: {
            dti: {
                name: '*DTI',
                file: null,
                date_issued: '',
                date_expiry: '',
                menuIssued: false,
                menuExpiry: false
            },
            business_permit: {
                name: '*Business Permit with Money Remittance/Remittance Agent',
                file: null,
                date_issued: '',
                date_expiry: '',
                menuIssued: false,
                menuExpiry: false
            },
            cmap: {
                name: '*CMAP',
                file: null
            },
            mother_of_contract: {
                name: 'Mother Contract',
                file: null
            },
            moa_avp: {
                name: 'AVP MOA',
                file: null
            },
            moa_nonex: {
                name: 'NONEX MOA',
                file: null
            },
            bir_cor: {
                name: '*BIR COR',
                file: null
            },
            supplier_reference: {
                name: '*Supplier Reference',
                file: null
            },
            fss_deed_of_undertaking:{
                name: '*FSS Licensing Form No. 01-003',
                file: null
            },

            fss_pdaf:{
                name: '*FSS Licensing Form No. 01-004 (PDAF)',
                file: null
            },
            fs_itr:{
                name: '*FS/ITR',
                file: null
            },
            amla_questionnaire:{
                name: '*AMLA Questionnaire',
                file: null
            },
            amla_cor: {
                name: '*AMLA COR',
                file: null,
                date_issued: '',
                date_expiry: '',
                menuIssued: false,
                menuExpiry: false
            },
        },
        cooperative_hybrid: {
            cda: {
                name: '*CDA',
                file: null,
                // date_issued: '',
                // date_expiry: '',
                // menuIssued: false,
                // menuExpiry: false
            },
            business_permit: {
                name: '*Business Permit with Money Remittance/Remittance Agent',
                file: null,
                date_issued: '',
                date_expiry: '',
                menuIssued: false,
                menuExpiry: false
            },
            cmap: {
                name: '*CMAP',
                file: null
            },
            mother_of_contract: {
                name: 'Mother Contract',
                file: null
            },
            secretary_certificate: {
                name: '*Secretary Certificate',
                file: null
            },
            bir_cor: {
                name: '*BIR COR/Certificate of Tax Exemption',
                file: null
            },
            supplier_reference: {
                name: '*Supplier Reference',
                file: null
            },
            org_chart: {
                name: '*Organizational Chart',
                file: null
            },
            board_resolution: {
                name: '*Board Resolution',
                file: null
            },
            fss_deed_of_undertaking:{
                name: '*FSS Licensing Form No. 01-003',
                file: null
            },

            fss_pdaf:{
                name: '*FSS Licensing Form No. 01-004 (PDAF)',
                file: null
            },

            fs_itr:{
                name: '*FS/ITR',
                file: null
            },
            moa_avp: {
                name: 'AVP MOA',
                file: null
            },
            moa_nonex: {
                name: 'NONEX MOA',
                file: null
            },
            amla_questionnaire:{
                name: '*AMLA Questionnaire',
                file: null
            },
            amla_cor: {
                name: '*AMLA COR',
                file: null,
                date_issued: '',
                date_expiry: '',
                menuIssued: false,
                menuExpiry: false
            },
        },
        corporation: {
            sec: {
                name: '*SEC',
                file: null,
                // date_issued: '',
                // date_expiry: '',
                // menuIssued: false,
                // menuExpiry: false
            },
            business_permit: {
                name: '*Business Permit with Money Remittance/Remittance Agent',
                file: null,
                date_issued: '',
                date_expiry: '',
                menuIssued: false,
                menuExpiry: false
            },
            gis: {
                name: '*GIS',
                file: null,
                date_issued: '',
                date_expiry: '',
                menuIssued: false,
                menuExpiry: false
            },
            cmap: {
                name: '*CMAP',
                file: null
            },
            mother_of_contract: {
                name: 'Mother Contract',
                file: null
            },
            secretary_certificate: {
                name: '*Secretary Certificate',
                file: null
            },
            bir_cor: {
                name: '*BIR COR',
                file: null
            },
            supplier_reference: {
                name: '*Supplier Reference',
                file: null
            },
            org_chart: {
                name: '*Organizational Chart',
                file: null
            },
            board_resolution: {
                name: '*Board Resolution',
                file: null
            },
            fss_deed_of_undertaking:{
                name: '*FSS Licensing Form No. 01-003',
                file: null
            },

            fss_pdaf:{
                name: '*FSS Licensing Form No. 01-004 (PDAF)',
                file: null
            },

            fs_itr:{
                name: '*FS/ITR',
                file: null
            },
            moa_avp: {
                name: 'AVP MOA',
                file: null
            },
            moa_nonex: {
                name: 'NONEX MOA',
                file: null
            },
            amla_questionnaire:{
                name: '*AMLA Questionnaire',
                file: null
            },
            amla_cor: {
                name: '*AMLA COR',
                file: null,
                date_issued: '',
                date_expiry: '',
                menuIssued: false,
                menuExpiry: false
            },
        }
    },
    
    primaryDetails: {},
    complianceOfficer: {
        first_name: '',
        middle_name: '',
        last_name: '',
        suffix: '',
        address_line_1: '',
        address_line_2: '',
        city: '',
        region: '',
        country: 'Philippines',
        province:'',
        postal_code: '',
        email_address: '',
        landline: '',
        cellphone: '',
        is_same_primary: null,
    },
    primaryBusinessAddress: {
        address_line_1: '',
        address_line_2: '',
        city: '',
        region: '',
        country: 'Philippines',
        province: '',
        postal_code: '',
        email_address: '',
        landline: '',
        cellphone: '',
        is_same_primary: null,
        mailing_address_line_1: '',
        mailing_address_line_2: '',
        mailing_city: '',
        mailing_region: '',
        mailing_country: 'Philippines',
        mailing_postal_code: '',
        mailing_province: '',
        mailing_designated_receiver: '',
        mailing_contact_number: ''
    },
    primaryContactInfo: {
        first_name: '',
        middle_name: '',
        last_name: '',
        suffix: '',
        address_line_1: '',
        address_line_2: '',
        city: '',
        region: '',
        country: 'Philippines',
        province: '',
        postal_code: '',
        email_address: '',
        landline: '',
        cellphone: '',
        is_same_primary: null
    },
    otherPrimaryDetails: {
        type: '',
        bsp_date_of_issue: '',
        bsp_rsa_number: '',
        business_information: '',
        business_type_of_sub_agents: '',
        company_registration_number: '',
        government_tax_id: '',
        is_kya_certified: false,
        kya_date_next_cert: '',
        list_of_avp_products: '',
        list_of_non_ex_remco: '',
        state_of_registration: 'Philippines',
        top_reasons_for_WU: '',
        type_of_entity: '',
        type_of_registration: '',
        typical_customers: '',
        years_in_business: '',
        is_western_union: false,
        single_location: null,
        mother_location: '',
        class_code_entity:'',
        company_id:'',
        is_rsa: false,
        is_tie_up: false,
        is_kya_pending: false,
        is_kya_recertification: false,
    },
    owners: 
        {
            first_name: '',
            middle_name: '',
            last_name: '',
            is_draft: '',
            suffix: '',
            percentage_ownership: '',
            address_line_1: '',
            address_line_2: '',
            country: 'Philippines',
            province:'',
            city: '',
            region: '',
            postal_code: '',
            landline: '',
            cellphone: '',
            email_address: '',
            valid_id: {
                file: null,
                // date_issued: '',
                // date_expiry: '',
                // menuIssued: false,
                // menuExpiry: false
            },
            court_clearance: {
                file: null,
                // date_issued: '',
                // date_expiry: '',
                // menuIssued: false,
                // menuExpiry: false
            },
            nbi: {
                file: null,
            },
            amla: {
                file: null,
                date_issued: '',
                date_expiry: '',
                menuIssued: false,
                menuExpiry: false
            },
            picture_2x2: {
                file: null
            },
            is_same_primary: null,
            no_of_members: null
        },
        bod:{
            first_name: '',
            middle_name: '',
            last_name: '',
            suffix: '',
            no_of_shares: '',
            position: '',
            is_familial: 0,
            address_line_1: '',
            address_line_2: '',
            country: 'Philippines',
            city: '',
            province: '',
            region: '',
            postal_code: '',
            date_of_birth: '',
            is_draft: '',
            dob: {
                menuIssued: false,
                menuExpiry: false
            },
            valid_id: {
                file: null,
                // date_issued: '',
                // date_expiry: '',
                // menuIssued: false,
                // menuExpiry: false
            },
        },
        bsp: {
            bsp_license: {
                file: null,
                date_issued: '',
                date_expiry: '',
                menuIssued: false,
                menuExpiry: false
            },
        },
    setComments: [],
    countries: null,
    zip: [],
    branch_business_permit: {
        id: '',
        file: null,
        date_expiry: '',
        date_issued: '',
        menuIssued: false,
        menuExpiry: false 
    },
    branchDialog: false,
    fla_amla: {
        id: '',
        file: null,
        date_expiry: '',
        date_issued: '',
        menuIssued: false,
        menuExpiry: false 
    },
    amlaDialog: false,
    owner_amla: {
        id: '',
        file: null,
        date_expiry: '',
        date_issued: '',
        menuIssued: false,
        menuExpiry: false 
    },
    ownerAmlaDialog: false,
    final_comment: '',
    setFinalComments: [],
    returnedEvaComments: [],
    disapproveComments: [],
    documentMaintenance: [],
    documents_maintenance: {
        document_title: '',
        is_default: true,
        primary: '',
        secondary: '',
        primary_date: {
            menuIssued: false,
            menuExpiry: false
        },
        secondary_date: {
            menuIssued: false,
            menuExpiry: false
        }
    },
    moa: {   
        moa_type: '',
        day_execution:'',
        month_execution: '',
        salutation: '',
        year_execution: '',
        address_execution: '',
        appendices: '',
        is_send: null,
        is_op_req_only: null,
        service_fee_words: '',
        service_fee_figures: '',
        period_term_months: '',
        period_term_years: '',
        sub_representative: {
            first_name: '',
            middle_name:'',
            last_name:'',
            suffix: '',
            position: '',
            id_expiry:'',
            id_type:'',
            id_number:'',
            menuIssued: false,
            menuExpiry: false
        },
        app_form: {
            account_name: '',
            address_line_1: '',
            address_line_2: '',
            city: '',
            region: '',
            country: '',
            postal_code: '',
            email_address: '',
            landline: '',
            cellphone: '',
            province: ''
        },
        cover_date_header: '',
        cover_dear: '',
        midnight_date: '',
        is_dengue: false,
        is_payapa: false,
        is_malayan: false,
        is_visa_selling: false,
        is_visa_cashin: false,
        is_gcash_in: false,
        is_gcash_out: false,
        is_paymaya: false,
        is_coins: false,
        is_dragonpay: false,
        is_unionbank: false,
        is_airline: false,
        is_eload: false,
        is_bills_pay: false,
        is_others: false,
        is_dengue_generated: false,
        is_payapa_generated: false,
        is_malayan_generated: false,
        is_visa_selling_generated: false,
        is_visa_cashin_generated: false,
        is_gcash_in_generated: false,
        is_gcash_out_generated: false,
        is_paymaya_generated: false,
        is_coins_generated: false,
        is_dragonpay_generated: false,
        is_unionbank_generated: false,
        is_airline_generated: false,
        is_eload_generated: false,
        is_bills_pay_generated: false,
        is_others_generated: false,
        other_products_array: [],
        other_products: '',
        to_email: [],
        cc_email: [],
        bcc_email: [],
        pet_name: 'Madeline Elba B. Gacutan',
        pet_id_type: 'UMID',
        pet_id_number: '0111-6405981-5',
        pet_id_expiry: 'N/A',
        pet_menuIssued: false,
        pet_menuExpiry: false,
        cover_name: '',
        cover_position: '',
        cover_department: '',
        cover_number: '',
        signatory: {
            first_name: '',
            middle_name: '',
            last_name: '',
            suffix: '',
            position: '',
            id_expiry:'',
            id_type:'',
            id_number:'',
        },
    },
    witness: {
        first_name: '',
        middle_name: '',
        last_name: '',
        suffix: '',
        position: '',
        valid_id: null
    },
    existingWitnessUploaded: [],
    signatory: {
        first_name: '',
        middle_name: '',
        last_name: '',
        suffix: '',
        position: '',
        id_expiry:'',
        id_type:'',
        id_number:'',
        valid_id: null,
        menuIssued: false,
        menuExpiry: false,
    },
    existingSignatoryUploaded: [],
    province: [],
    businessAddCity:[],
    businessAddZip:[],
    mailingBusinessAddCity:[],
    mailingBusinessAddZip:[],
    primaryContactCity:[],
    primaryContactZip:[],
    complianceCity: [],
    complianceZip: [],
    branchesCity: [],
    branchesZip: [],
    existingBranchesCity: [],
    existingBranchesCityMailing: [],
    existingBranchesZip: [],
    existingBranchesZipMailing: [],
    ownersCity: [],
    ownersZip: [],
    existingOwnersCity: [],
    existingOwnersZip: [],
    bodCity: [],
    bodZip: [],
    existingBodCity: [],
    existingBodZip: [],
    compliance_returned_comments: [],
    encoder_returned_comments: [],
    compliance_approved_comments: [],
    logsDialog: false,
    sasDetails: [],
    clusterDetails: [],
    users: [],
    primaryDetailsRadio1: '',
    primaryDetailsRadio2: '',
    avp_details: '',
    branchMailingCity: [],
    branchMailingZip: [],
    mother_entity_details: {
        mother_entity_id: null,
        is_mother_entity: false,
        sub_entities: [],
    }
}