const SET_BSP_ID = (state, payload) => {
    state.bspId = payload
};

const UNSET_BSP_ID = (state) => {
    state.bspId = ''
};

const SET_DET_DETAILS_DEFAULT = (state) => {
    state.detDetails = {
        is_draft: false,
        citizenship: 'Filipino',
        tin_no: '',
        fax_no: '',
        det_type: '',
        ownership_type: ''
    }
}

const SET_DET_DETAILS = (state, payload) => {
    state.detDetails = {
        is_draft: false,
        citizenship: payload.sub_agent_information.citizenship,
        tin_no: payload.sub_agent_information.tin_no,
        fax_no: payload.sub_agent_information.fax_no,
        det_type: payload.det_type,
        ownership_type: payload.ownership_type,
    },
    state.compliance = {
        detStatus: payload.det_status
    }
}

const SET_DEF_FILES = (state, payload) => {
    state.existingDetFiles = payload
}

const SET_DEF_FILES_DEFAULT = (state) => {
    state.existingDetFiles = []
}

const SET_COMMENT = (state, payload) => {
    state.comment = payload
}

const SET_COMMENT_DEFAULT = (state) => {
    state.comment = ''
}

const DET_FILES_DEFAULT = (state) => {
    state.detFilesUpload.fss_02_001 = {
        file: null
    },
    state.detFilesUpload.det_fss_03_001= {
        file: null
    },
    state.detFilesUpload.fss_03_003= {
        file: null
    },
    state.detFilesUpload.fss_03_005= {
        file: null
    },
    state.detFilesUpload.det_form_03_009= {
        file: null
    },
    state.detFilesUpload.det_form_2m= {
        file: null
    },
    state.detFilesUpload.det_form_3m= {
        file: null
    },
    state.detFilesUpload.form_3m_1= {
        file: null
    },
    state.detFilesUpload.form_3m_2= {
        file: null
    },
    state.detFilesUpload.form_3m_3= {
        file: null
    },
    state.detFilesUpload.fss_03_002= {
        file: null
    }
    state.detFilesUpload.det_form_4m= {
        file: null
    }
    state.detFilesUpload.det_form_5m= {
        file: null
    }
    state.detFilesUpload.det_form_6m= {
        file: null
    }
    state.detFilesUpload.det_form_7m= {
        file: null
    }
    state.detFilesUpload.det_form_8m= {
        file: null
    }
    state.detFilesUpload.det_form_9m= {
        file: null
    }
    state.detFilesUpload.det_form_10m= {
        file: null
    },
    state.detFilesUpload.letter_of_intent= {
        file: null
    },
    state.detFilesUpload.proof_of_payment= {
        file: null
    },
    state.detFilesUpload.letter_informing= {
        file: null
    },
    state.detFilesUpload.notarized_statement= {
        file: null
    },
    state.detFilesUpload.bsp_cor= {
        file: null
    },
    state.detFilesUpload.bsp_rmp= {
        file: null
    },
    state.detFilesUpload.payment_bsp_assessment= {
        file: null
    },
    state.detFilesUpload.vendee_transferee= {
        file: null
    },
    state.detFilesUpload.contract_change_ownership= {
        file: null
    },
    state.detFilesUpload.proof_payment_filing_fee= {
        file: null
    },
    state.detFilesUpload.nbi_clearance= {
        file: null
    },
    state.detFilesUpload.proof_attendance= {
        file: null
    },
    state.detFilesUpload.schedule_ownership= {
        file: null
    },
    state.detFilesUpload.death_certificate= {
        file: null
    },
    state.detFilesUpload.extrajudicial_settlement= {
        file: null
    },
    state.detFilesUpload.affidavit_release= {
        file: null
    },
    state.detFilesUpload.compliance_cor_1= {
        file: null
    },
    state.detFilesUpload.notarized_det_3m= {
        file: null
    },
    state.detFilesUpload.contract_agreement= {
        file: null
    },
    state.detFilesUpload.business_license= {
        file: null
    },
    state.detFilesUpload.amlcs= {
        file: null
    }
    state.detFilesUpload.mother_of_contract= {
        file: null
    }
}

const SET_ENCODER_VIEW = (state, payload) => {
    state.disable_encoder_view = payload
};

const SET_DETENCODER_VIEW = (state, payload) => {
    state.disable_det_view = payload
};

const SET_COMPLIANCEUPLOAD_DEFAULT = (state) =>{
    state.complianceUpload.norr_fsd9= {
        file: null
    },
    state.complianceUpload.norr_fss_mail= {
        file: null
    },
    state.complianceUpload.oprn_invoice= {
        file: null
    },
    state.complianceUpload.oprn_receipt= {
        file: null
    },
    state.complianceUpload.settle_oprn_payment= {
        file: null
    },
    state.complianceUpload.accreditation_letter= {
        file: null
    }
}

const SET_COMPLIANCE_DOCS = (state, payload) => {
    state.existingComplianceDocs = payload
}

const SET_ISPROCESS_BSP = (state, payload) => {
    state.complianceUpload.is_process_bsp = payload
}


export default {
    SET_BSP_ID,
    UNSET_BSP_ID,
    SET_DET_DETAILS_DEFAULT,
    SET_DET_DETAILS,
    SET_DEF_FILES,
    SET_DEF_FILES_DEFAULT,
    DET_FILES_DEFAULT,
    SET_COMMENT,
    SET_COMMENT_DEFAULT,
    SET_ENCODER_VIEW,
    SET_DETENCODER_VIEW,
    SET_COMPLIANCEUPLOAD_DEFAULT,
    SET_COMPLIANCE_DOCS,
    SET_ISPROCESS_BSP
}