<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      persistent
      width="600"
    >
      <v-card>
        <v-card-title class="ph-dialog-title">
          Refresh Session
        </v-card-title>

        <v-card-text class="ph-dialog-header">
          <br>
          <p>Your session will expire soon, please click <b>"Refresh Session"</b> to continue.</p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="ph-dialog-actions">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="ph-action-button"
            text
            @click="logout('byButton')"
          >
            Logout
            &nbsp;
            <v-progress-circular
              :rotate="360"
              :value="progressValue"
              color="primary"
            >
              {{ countdown }}
            </v-progress-circular>
          </v-btn>
          <v-btn
            v-if="environment == 'Production' || environment == 'Staging' || environment == 'Local'"
            color="primary"
            class="ph-action-button"
            @click="refreshToken"
          >
            Refresh Session
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Vue from 'vue';

export default {
  name: 'TokenReminder',
  data () {
    return {
      dialog: false,
      interval: {},
      countdown: 60,
      progressValue: 0,
      environment: process.env.VUE_APP_ENV,
    }
  },
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser',
      showRefreshPrompt: 'auth/showRefreshPrompt',
      authTimestamp: 'auth/authTimestamp'
    })
  },
  watch: {
    // currUser: {
    //   // handler (value) {
    //   //   console.log(value)
    //   // },
    //   deep: true
    // },
    showRefreshPrompt: {
      handler (value) {
        console.log('dialog value:' + value)
        this.dialog = value

        if (this.dialog === true && this.$route.name !== 'Login') {
          this.startPromptCountdown()
        }
      },
      deep: true
    },
    countdown: {
      handler (count) {
        if (count <= 0) {
          const that = this
          this.$store.commit('auth/SET_SwHOW_REFRESH', false)

          setTimeout(function () {
            that.progressValue = 0
            clearInterval(this.interval)
            that.$store.commit('auth/SET_RUN_SESSION', false)
            that.$store.commit('auth/SET_SHOW_REFRESH', false)
          }, 1000)
            console.log('HANDLER LOGOUT')
            this.logout()

        }
      }
    }
  },
  mounted () {
    this.dialog = this.showRefreshPrompt

    if (this.dialog && this.$route.name !== 'Login') {
      this.startPromptCountdown()
    }
  },
  methods: {
    startPromptCountdown () {
      const currentTime = new Date()
      const loggedInTime = new Date(this.authTimestamp.timeLoggedIn)
      const actualExpireTime = new Date(loggedInTime.getTime() + (50 * 60 * 1000))

      if (currentTime.getTime() > actualExpireTime.getTime()) {
        this.logout()
      } else {
        this.interval = setInterval(() => {
          if (this.progressValue === 99.99 && this.countdown === 0) {
            return (this.progressValue = 0)
          }
          this.progressValue += 1.66666666667
          this.countdown -= 1
        }, 1000)
      }
    },
    
    // Okta Refresh

    // async refreshToken () {
    //   clearInterval(this.interval)
    //   this.countdown = 60
		// 	const token = localStorage.getItem('auth-data')
		// 	const newToken = JSON.parse(token)
		// 	const payload = newToken.okta.refresh_token
		// 	const res = await this.$store.dispatch('auth/doRefreshToken', payload)
		// 	if(res.status === 200) {

    //     this.$store.commit('auth/UNSET_AUTH_TIMESTAMP');
		// 		console.log(res.data.result)

		// 		var existing = localStorage.getItem('auth-data');
				
		// 		existing = existing ? JSON.parse(existing) : {};

		// 		// Add new data to localStorage Array
		// 		existing['hris_token'] = res.data.result.hris_token;
		// 		existing.okta['id_token'] = res.data.result.okta_id_token;
		// 		existing.okta['refresh_token'] = res.data.result.okta_refresh_token;
		// 		existing['token'] = res.data.result.api_token;

		// 		// Save back to localStorage
		// 		localStorage.setItem('auth-data', JSON.stringify(existing));
		// 		Vue.$cookies.set('auth-data', existing);

		// 		localStorage.setItem('hris-token', existing.hris_token);
		// 		this.$store.commit('auth/SET_HRIS_TOKEN', existing.hris_token)

    //     // Set new time stamp
    //     const currentTime = new Date()
		// 		const expireTime = new Date(currentTime.getTime() + (2 * 60 * 1000))
		// 		this.$store.commit('auth/SET_AUTH_TIMESTAMP', {
		// 			timeLoggedIn: currentTime,
		// 			timeToExpire: expireTime
		// 		})

    //     this.$store.commit('auth/SET_SHOW_REFRESH', false
    //     )
		// 	}

		// },

    // Google Refresh

    async refreshToken () {
      clearInterval(this.interval)
      this.countdown = 60
			const token = localStorage.getItem('auth-data')
			const newToken = JSON.parse(token)

      if (newToken.google){
        console.log('Google Refresh')
        const payload = newToken.google.refresh_token
        const res = await this.$store.dispatch('auth/doGoogleRefreshToken', payload)
        if(res.status === 200) {

          this.$store.commit('auth/UNSET_AUTH_TIMESTAMP');
          console.log('HERE')
          console.log(res.data.result)

          var existing = localStorage.getItem('auth-data');
          
          existing = existing ? JSON.parse(existing) : {};

          // Add new data to localStorage Array
          existing['hris_token'] = res.data.result.hris_token;
          existing.google['id_token'] = res.data.result.google_id_token;
          existing.google['refresh_token'] = res.data.result.google_refresh_token;
          existing['token'] = res.data.result.api_token;

          // Save back to localStorage
          localStorage.setItem('auth-data', JSON.stringify(existing));
          Vue.$cookies.set('auth-data', existing);

          localStorage.setItem('hris-token', existing.hris_token);
          this.$store.commit('auth/SET_HRIS_TOKEN', existing.hris_token)

          // Set new time stamp
          const currentTime = new Date()
          const expireTime = new Date(currentTime.getTime() + (45 * 60 * 1000))
          this.$store.commit('auth/SET_AUTH_TIMESTAMP', {
            timeLoggedIn: currentTime,
            timeToExpire: expireTime
          })

          this.$store.commit('auth/SET_SHOW_REFRESH', false
          )
        }
      } else if (newToken.okta){
        console.log('Okta Refresh')
        const payload = newToken.okta.refresh_token
        const res = await this.$store.dispatch('auth/doRefreshToken', payload)
        if(res.status === 200) {

          this.$store.commit('auth/UNSET_AUTH_TIMESTAMP');
          console.log(res.data.result)

          var existing2 = localStorage.getItem('auth-data');
          
          existing2 = existing2 ? JSON.parse(existing2) : {};

          // Add new data to localStorage Array
          existing2['hris_token'] = res.data.result.hris_token;
          existing2.okta['id_token'] = res.data.result.okta_id_token;
          existing2.okta['refresh_token'] = res.data.result.okta_refresh_token;
          existing2['token'] = res.data.result.api_token;

          // Save back to localStorage
          localStorage.setItem('auth-data', JSON.stringify(existing2));
          Vue.$cookies.set('auth-data', existing2);

          localStorage.setItem('hris-token', existing2.hris_token);
          this.$store.commit('auth/SET_HRIS_TOKEN', existing2.hris_token)

          // Set new time stamp
          const currentTime = new Date()
          const expireTime = new Date(currentTime.getTime() + (45 * 60 * 1000))
          this.$store.commit('auth/SET_AUTH_TIMESTAMP', {
            timeLoggedIn: currentTime,
            timeToExpire: expireTime
          })

          this.$store.commit('auth/SET_SHOW_REFRESH', false
          )
        }
      }
		},

    //Okta Logout
		// async logout(){
    //   const token = localStorage.getItem('auth-data')
    //   const newToken = JSON.parse(token)
    //   const payload = newToken.okta.id_token
    //   const res = await this.$store.dispatch('auth/doLogout', payload)
    //   const newWindow = this.openWindow('', 'message');
    //   const urlAccountSelection = res.data.result;
    //   newWindow.location.href = urlAccountSelection
    //   if(res.status === 200) {
    //     this.$store.commit('auth/UNSET_AUTH_TIMESTAMP');
    //     this.$router.push('/');
    //     location.reload();
    //   }
		// },

    //Google Logout
    async logout(){
      const token = localStorage.getItem('auth-data')
			const newToken = JSON.parse(token)
      if (newToken.okta){
        console.log('Okta Logout')
        const token = localStorage.getItem('auth-data')
        const newToken = JSON.parse(token)
        const payload = newToken.okta.id_token
        const res = await this.$store.dispatch('auth/doLogout', payload)
        const newWindow = this.openWindow('', 'message');
        const urlAccountSelection = res.data.result;
        newWindow.location.href = urlAccountSelection
        if(res.status === 200) {
          this.$router.push('/');
          this.$store.commit('auth/UNSET_AUTH_TIMESTAMP');
          this.$store.commit('auth/SET_SHOW_REFRESH', false)
          location.reload();
        }
      } else if (newToken.google){
        console.log('Google Logout')
        const res = await this.$store.dispatch('auth/doGoogleLogout')
        if(res.status === 200) {
          this.$router.push('/');
          this.$store.commit('auth/UNSET_AUTH_TIMESTAMP');
          this.$store.commit('auth/SET_SHOW_REFRESH', false)
          location.reload();
        }
      }
		},
		openWindow (url, title, options = {}) {
			if (typeof url === 'object') {
				options = url;
				url = '';
			}
			options = { url, title, width: 600, height: 720, ...options };
		
			const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screen.left;
			const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screen.top;
			const width = window.innerWidth || document.documentElement.clientWidth || window.screen.width;
			const height = window.innerHeight || document.documentElement.clientHeight || window.screen.height;
		
			options.left = ((width / 2) - (options.width / 2)) + dualScreenLeft;
			options.top = ((height / 2) - (options.height / 2)) + dualScreenTop;
		
			const optionsStr = Object.keys(options).reduce((acc, key) => {
				acc.push(`${key}=${options[key]}`);
				return acc;
			}, []).join(',');
		
			const newWindow = window.open(url, title, optionsStr);
		
			if (window.focus) {
				newWindow.focus();
			}
			return newWindow;
		},
  }
}
</script>
<style scoped>
@media only screen and (max-width: 599px) {
  .ph-dialog-actions {
    display: flex;
    flex-direction: column;
  }

  .ph-dialog-actions .ph-action-button {
    width: 100%;
    margin-left: 0 !important;
    margin-top: 10px;
  }
}
</style>
