<template>
	<nav>
		<link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0" />
		<!-- App Bar -->
		<v-app-bar dark dense app color="white">
			<v-app-bar-title>
			<span class="title appbar-title mr-5">
				<img :src="image" />
			</span>
			</v-app-bar-title>
			<v-spacer />

			<!-- <v-btn icon color="primary">
				<v-icon>mdi-bell</v-icon>
			</v-btn> -->

			<v-menu offset-y transition="slide-y-transition" >
				<template v-slot:activator="{ on }">

					<v-btn icon color="primary" v-show="isAuthenticated" v-on="on">
						<v-icon>mdi-bell</v-icon>
					</v-btn>
					
				</template>
				
				<notifications/>

			</v-menu>



			<v-menu offset-y transition="slide-y-transition" >
				<template v-slot:activator="{ on }">

					<v-btn text large v-show="isAuthenticated" v-on="on" icon v-if="!profile">
						<v-avatar size="25px" :style="avatarColor(currUser.name)">
                            <span class="font-color:black;">{{ avatarInitial(currUser.name) }}</span>
                        </v-avatar>
					</v-btn>
					<v-btn text large v-show="isAuthenticated" v-on="on" icon v-else>
						<img :src="profile" alt="profile" class="picture-icon">
					</v-btn>
					
				</template>

				<v-card
					class="mx-auto pb-3"
					width="250"
				>
					<v-card-text>
					<p class="text-h5 text--primary">{{currUser.name}}</p>

					<h4>Role:</h4>
					<p class="mt-2">{{nav_user_role}}</p>

					<h4>User Type:</h4>
					<p v-for="(item, parentIndex) in this.user_type" :key="parentIndex" class="mt-2">
						{{item}}</p>
					
					</v-card-text>
				</v-card>

				<!-- <v-list>
					<v-list-item style="cursor: pointer">
						<v-list-item-title>Account Settings</v-list-item-title>
					</v-list-item>
					<v-list-item style="cursor: pointer">
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
							<span
								v-bind="attrs"
								v-on="on"
							>User
							</span>
							</template>
							<span>User Role:</span>
							<br>
							<b>{{user_role}}</b>
							<br>
							<span>User Type:</span>
							<br>
							<b v-for="(type, index) in user_type" :key="index">{{type}} | </b>
						</v-tooltip>
					</v-list-item>
				</v-list> -->

			</v-menu>
		</v-app-bar>

		<!-- Drawer -->
		<v-navigation-drawer v-model="drawer" app
			dark
			:mini-variant.sync="mini" permanent 
			mobile-breakpoint="991"
			expand-on-hover
			class="custom-color"
			>
			<!-- <template v-slot:img="attrs">
				<v-img
					v-bind="attrs"
					gradient="to top, rgba(0, 0, 0, .7), rgba(0, 0, 0, .7)"
				/>
			</template> -->
			<v-list dense class="pt-3 white--text" style="font-color:white;">
				<v-list-item router to="/dashboard" active-class="primary white--text">
					<v-list-item-icon>
						<v-icon>mdi-view-dashboard</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>Dashboard</v-list-item-title>
					</v-list-item-content>
				</v-list-item>

				<!-- Approver Side -->

				<v-list-item router to="/sales-prospecting" active-class="primary white--text" v-if="currUser.user_role == 'approver' && (currUser.is_president == 1 || currUser.is_onboarding_committee == 1)">
					<v-list-item-icon>
						<v-icon>mdi-clipboard-text</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>Sales Prospect (Approval)</v-list-item-title>
					</v-list-item-content>
				</v-list-item>

				<v-list-item router to="/sales-prospecting-eval" active-class="primary white--text" v-if="currUser.is_evaluation_committee == 1">
					<v-list-item-icon>
						<v-icon>mdi-clipboard-text</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>Sales Prospect (Approval)</v-list-item-title>
					</v-list-item-content>
				</v-list-item>

				<v-list-item router to="/existing-sales-prospecting" active-class="primary white--text" v-if="currUser.user_role == 'approver' && currUser.is_validation_committee == 1">
					<v-list-item-icon>
						<v-icon>mdi-clipboard-text</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>Sales Prospect (Approval)</v-list-item-title>
					</v-list-item-content>
				</v-list-item>

				<!-- Onboarding Committee Side -->

				<v-list-item router to="/merchant-final" active-class="primary white--text" v-if="currUser.user_role == 'approver' && currUser.is_onboarding_committee == '1'">
					<v-list-item-icon>
						<v-icon>mdi-file-tree</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>Merchant Enrollment</v-list-item-title>
					</v-list-item-content>
				</v-list-item>


				<v-list-group prepend-icon="mdi-finance" active-class="primary grey--text" v-if="currUser.user_role == 'approver' && currUser.is_onboarding_committee == '1'">
					<v-list-item-title slot="activator">
							<div>Finance</div>
					</v-list-item-title>
					
					<v-list-item  router to="/finance-onboarding" active-class="primary white--text">
						&ensp;
						<v-list-item-icon>
							<v-icon>mdi-clipboard-text</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>Finance (Approval)</v-list-item-title>
						</v-list-item-content>
					</v-list-item>

					<v-list-item  router to="/finance-banks" active-class="primary white--text">
						&ensp;
						<v-list-item-icon>
							<v-icon>mdi-bank-outline</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>Finance (Bank Details)</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					
				</v-list-group>

				<v-list-group active-class="primary grey--text" prepend-icon="mdi-account-group-outline" v-if="currUser.user_role == 'approver' && currUser.is_onboarding_committee == '1'">
					<v-list-item-title slot="activator">
							<div>BP Accounts</div>
					</v-list-item-title>
					<v-list-item router to="/onboarding-suspension" active-class="primary white--text">
						&ensp;
						<v-list-item-icon>
							<v-icon>mdi-account-lock-outline</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>Suspension</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item router to="/onboarding-deactivation" active-class="primary white--text">
						&ensp;
						<v-list-item-icon>
							<v-icon>mdi-account-cancel-outline</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>Deactivation</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item router to="/onboarding-reactivation" active-class="primary white--text">
						&ensp;
						<v-list-item-icon>
							<v-icon>mdi-account-reactivate-outline</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>Reactivation</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item router to="/archived-list" active-class="primary white--text">
						&ensp;
						<v-list-item-icon>
							<v-icon>mdi-archive</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>Archived</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list-group>

				<!-- Onboarding Head View -->

				<v-list-item router to="/finance-onboarding-head" active-class="primary white--text" v-if="currUser.user_role == 'approver' && currUser.is_oh_approver == '1'">
					<v-list-item-icon>
						<v-icon>mdi-finance</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>Finance (Approval)</v-list-item-title>
					</v-list-item-content>
				</v-list-item>

				<v-list-group active-class="primary grey--text" prepend-icon="mdi-account-group-outline" v-if="currUser.user_role == 'approver' && currUser.is_oh_approver == '1'">
					<v-list-item-title slot="activator">
							<div>BP Accounts</div>
					</v-list-item-title>
					<v-list-item router to="/onboarding-head-suspension" active-class="primary white--text">
						&ensp;
						<v-list-item-icon>
							<v-icon>mdi-account-lock-outline</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>Suspension (Approval)</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item router to="/onboarding-head-deactivation" active-class="primary white--text">
						&ensp;
						<v-list-item-icon>
							<v-icon>mdi-account-cancel-outline</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>Deactivation (Approval)</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item router to="/onboarding-head-reactivation" active-class="primary white--text">
						&ensp;
						<v-list-item-icon>
							<v-icon>mdi-account-reactivate-outline</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>Reactivation</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item router to="/archived-list" active-class="primary white--text">
						&ensp;
						<v-list-item-icon>
							<v-icon>mdi-archive</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>Archived</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list-group>

				<!-- SAS Committee Side -->

				<v-list-item router to="/sas-table" active-class="primary white--text" v-if="currUser.user_role == 'admin' && currUser.is_sas_committee == 1">
					<v-list-item-icon>
						<v-icon>mdi-clipboard-text-multiple</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>Sales Prospect</v-list-item-title>
					</v-list-item-content>
				</v-list-item>

				<!-- Finance Committee -->
			
				<v-list-item router to="/finance-committee" active-class="primary white--text" v-if="currUser.is_finance_committee === '1'">
					<v-list-item-icon>
						<v-icon>mdi-bank</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>
							Finance Bank Accounts</v-list-item-title>
					</v-list-item-content>
				</v-list-item>

				<!-- Admin Side -->
				

				<v-list-group active-class="primary grey--text" prepend-icon="mdi-folder" v-if="currUser.user_role === 'admin' && currUser.is_sas_committee == 0 && currUser.is_finance_committee == 0">
					<v-list-item-title slot="activator">
							<div>Maintenance</div>
					</v-list-item-title>
					<v-list-item router to="/mail-recipients" active-class="primary white--text">
						&ensp;
						<v-list-item-icon>
							<v-icon>mdi-email</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>Email Maintenance</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item router to="/document-maintenance" active-class="primary white--text">
						&ensp;
						<v-list-item-icon>
							<v-icon>mdi-file-document</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>Document Maintenance</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<!-- <v-list-item router to="/score-card-maintenance" active-class="primary white--text">
						<v-list-item-icon>
							<v-icon>mdi-view-module</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>Score Card Maintenance</v-list-item-title>
						</v-list-item-content>
					</v-list-item> -->
				</v-list-group>

				<v-list-group active-class="primary grey--text" prepend-icon="mdi-view-dashboard-edit" v-if="currUser.user_role === 'admin' && currUser.is_sas_committee == 0 && currUser.is_finance_committee == 0">
					<v-list-item-title slot="activator">
							<div>Dashboard Maintenance</div>
					</v-list-item-title>
					<v-list-item router to="/faq-maintenance" active-class="primary white--text">
						&ensp;
						<v-list-item-icon>
							<v-icon>mdi-message-question</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>FAQ Maintenance</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item router to="/status-maintenance" active-class="primary white--text">
						&ensp;
						<v-list-item-icon>
							<v-icon>mdi-list-status</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>Status Maintenance</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item router to="/acronym-maintenance" active-class="primary white--text">
						&ensp;
						<v-list-item-icon>
							<v-icon>mdi-format-text</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>Acronym Maintenance</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list-group>

				<v-list-group prepend-icon="mdi-account-switch-outline" active-class="primary grey--text" v-if="currUser.user_role == 'admin' && currUser.is_finance_committee == 0">
					<v-list-item-title slot="activator">
							<div>Information System</div>
					</v-list-item-title>
					<v-list-item  router to="/is-primary" active-class="primary white--text" v-if="currUser.user_role == 'admin'">
						&ensp;
						<v-list-item-icon>
							<v-icon>mdi-merge</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>Merge</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item  router to="/is-standalone" active-class="primary white--text" v-if="currUser.user_role == 'admin'">
						&ensp;
						<v-list-item-icon>
							<v-icon>mdi-set-merge</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>Standalone Merge</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list-group>

				<!-- <v-list-item router to="/finance-encoder" active-class="primary white--text" v-if="currUser.user_role == 'encoder' && currUser.is_det_encoder === '0'">
					<v-list-item-icon>
						<v-icon>mdi-finance</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>
							Finance</v-list-item-title>
					</v-list-item-content>
				</v-list-item> -->

				<v-list-group prepend-icon="mdi-finance" active-class="primary grey--text" v-if="currUser.user_role == 'admin' && currUser.is_finance_committee == 0">
					<v-list-item-title slot="activator">
							<div>Finance Merge</div>
					</v-list-item-title>
					<v-list-item  router to="/finance-imports" active-class="primary white--text" v-if="currUser.user_role == 'admin'">
						&ensp;
						<v-list-item-icon>
							<v-icon>mdi-merge</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>Merge</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					
				</v-list-group>

				<v-list-item router to="/clustering" active-class="primary white--text" v-if="currUser.user_role == 'admin' && currUser.is_sas_committee == 0 && currUser.is_finance_committee == 0">
					<v-list-item-icon>
						<v-icon>mdi-account-box-multiple-outline</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>Clustering</v-list-item-title>
					</v-list-item-content>
				</v-list-item>

				<v-list-item router to="/all-sales-prospect" active-class="primary white--text" v-if="currUser.user_role == 'admin' && currUser.is_sas_committee == 0">
					<v-list-item-icon>
						<v-icon>mdi-clipboard-text-multiple</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>Sales Prospect List</v-list-item-title>
					</v-list-item-content>
				</v-list-item>

				<v-list-item router to="/revert-status" active-class="primary white--text" v-if="currUser.user_role == 'admin' && currUser.is_sas_committee == 0">
					<v-list-item-icon>
						<v-icon>mdi-backup-restore</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>Approved BP Status (Revert)</v-list-item-title>
					</v-list-item-content>
				</v-list-item>

				<!-- Super Admin Side -->

				<v-list-item router to="/users" active-class="primary white--text" v-if="currUser.user_role === 'super_admin'">
					<v-list-item-icon>
						<v-icon>mdi-account-multiple</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>User Roles</v-list-item-title>
					</v-list-item-content>
				</v-list-item>

				<v-list-item router to="/add-user" active-class="primary white--text" v-if="currUser.user_role === 'super_admin'">
					<v-list-item-icon>
						<v-icon>mdi-account-multiple-plus</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>Add User</v-list-item-title>
					</v-list-item-content>
				</v-list-item>

				<!-- <v-list-item active-class="primary white--text" v-if="currUser.user_role === 'super_admin'" @click="viewLogs">
					<v-list-item-icon>
						<v-icon>mdi-newspaper-variant-multiple</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>View Logs</v-list-item-title>
					</v-list-item-content>
					<LogsView :item="logsData"> </LogsView>
				</v-list-item> -->
				

				<!-- Encoder Side -->
				<!-- <span class="material-symbols-outlined">diversity_2</span> -->

				<v-list-group prepend-icon="mdi-human-capacity-increase" active-class="primary grey--text" v-if="currUser.user_role == 'encoder' && currUser.is_det_encoder != '1'">
					<v-list-item-title slot="activator">
							<div>Sub-Agents</div>
					</v-list-item-title>
					<v-list-item  router to="/sales-prospecting" active-class="primary white--text" v-if="currUser.user_role == 'encoder' && currUser.is_det_encoder != '1'">
						&ensp;
						<v-list-item-icon>
							<v-icon>mdi-account-multiple-outline</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>Sales Prospect</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item router to="/existing-sales-prospecting" active-class="primary white--text" v-if="currUser.user_role == 'encoder' && currUser.is_det_encoder != '1'">
						&ensp;
						<v-list-item-icon>
							<v-icon>mdi-handshake-outline</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>
								Pera Remit Partner (PRP) Updating</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list-group>

				<v-list-group prepend-icon="mdi-account-switch-outline" active-class="primary grey--text" v-if="currUser.user_role == 'encoder' && currUser.is_det_encoder != '1'">
					<v-list-item-title slot="activator">
							<div>Information System</div>
					</v-list-item-title>
					<v-list-item  router to="/is-final" active-class="primary white--text" v-if="currUser.user_role == 'encoder' && currUser.is_det_encoder != '1'">
						&ensp;
						<v-list-item-icon>
							<v-icon>mdi-account-convert-outline</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>IS Details</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list-group>

				<v-list-group active-class="primary grey--text" prepend-icon="mdi-account-group-outline" v-if="currUser.user_role == 'encoder' && currUser.is_det_encoder != '1'">
					<v-list-item-title slot="activator">
							<div>BP Accounts</div>
					</v-list-item-title>
					<v-list-item router to="/encoder-suspension" active-class="primary white--text">
						&ensp;
						<v-list-item-icon>
							<v-icon>mdi-account-lock-outline</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>Suspension</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item router to="/encoder-deactivation" active-class="primary white--text">
						&ensp;
						<v-list-item-icon>
							<v-icon>mdi-account-cancel-outline</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>Deactivation</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item router to="/encoder-reactivation" active-class="primary white--text">
						&ensp;
						<v-list-item-icon>
							<v-icon>mdi-account-reactivate-outline</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>Reactivation</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item router to="/archived-list" active-class="primary white--text">
						&ensp;
						<v-list-item-icon>
							<v-icon>mdi-archive</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>Archived</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list-group>

				<v-list-item router to="/merchant-initial" active-class="primary white--text" v-if="currUser.user_role == 'encoder' && currUser.is_det_encoder === '0'">
					<v-list-item-icon>
						<v-icon>mdi-file-tree</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>Merchant Enrollment</v-list-item-title>
					</v-list-item-content>
				</v-list-item>

				<!-- Finance Part -->

				<v-list-item router to="/finance-encoder" active-class="primary white--text" v-if="currUser.user_role == 'encoder' && currUser.is_det_encoder === '0'">
					<v-list-item-icon>
						<v-icon>mdi-finance</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>
							Finance</v-list-item-title>
					</v-list-item-content>
				</v-list-item>

				<!-- BSP PART -->

				<v-list-item router to="/bsp" active-class="primary white--text" v-if="currUser.user_role == 'encoder' && currUser.is_det_encoder === '0'">
					<v-list-item-icon>
						<v-icon>mdi-file-table-box-outline</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>
							DET Forms</v-list-item-title>
					</v-list-item-content>
				</v-list-item>

				<v-list-item router to="/bsp-encoder" active-class="primary white--text" v-if="currUser.user_role == 'encoder' && currUser.is_det_encoder === '1'">
					<v-list-item-icon>
						<v-icon>mdi-file-document-edit-outline</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>
							DET Encoder</v-list-item-title>
					</v-list-item-content>
				</v-list-item>

				<v-list-item router to="/bsp-approver" active-class="primary white--text" v-if="currUser.user_role == 'approver' && currUser.is_det_compliance_approver === '1'">
					<v-list-item-icon>
						<v-icon>mdi-file-clock</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>
							DET (Approval)
						</v-list-item-title>
					</v-list-item-content>
				</v-list-item>

			<!-- SP List -->

			<v-list-item router to="/all-sales-prospect" active-class="primary white--text" v-if="currUser.user_role == 'approver' || currUser.user_role == 'auditor' || currUser.is_det_encoder === '1' || (currUser.user_role == 'encoder' && currUser.is_evaluation_committee == 1)">
				<v-list-item-icon>
					<v-icon>mdi-clipboard-text-multiple</v-icon>
				</v-list-item-icon>
				<v-list-item-content>
					<v-list-item-title>Sales Prospect List</v-list-item-title>
				</v-list-item-content>
			</v-list-item>

			<v-list-item router to="/sp-termination-list" active-class="primary white--text" v-if="currUser.is_control_committee == 1">
				<v-list-item-icon>
					<v-icon>mdi-delete-alert-outline</v-icon>
				</v-list-item-icon>
				<v-list-item-content>
					<v-list-item-title>Deletion (Approval)</v-list-item-title>
				</v-list-item-content>
			</v-list-item>

			<v-list-item router to="/profiling" active-class="primary white--text" v-if="(currUser.cluster_not_assigned == null || currUser.cluster_not_assigned == '') && currUser.user_role != 'auditor' && currUser.is_finance_committee == 0">
				<v-list-item-icon>
					<v-icon>mdi-layers-edit</v-icon>
				</v-list-item-icon>
				<v-list-item-content>
					<v-list-item-title>Profiling</v-list-item-title>
				</v-list-item-content>
			</v-list-item>

			<v-list-item router to="/reports" active-class="primary white--text">
				<v-list-item-icon>
					<v-icon>mdi-microsoft-excel</v-icon>
				</v-list-item-icon>
				<v-list-item-content>
					<v-list-item-title>Reports</v-list-item-title>
				</v-list-item-content>
			</v-list-item>

		</v-list>
			
			<template v-slot:append>
				<v-list dense class="pt-3 white--text" style="font-color:white;">
					<!-- <v-list-item @click="logout">
						<v-list-item-icon>
							<v-icon>mdi-exit-to-app</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>Logout</v-list-item-title>
						</v-list-item-content>
					</v-list-item> -->
					<v-list-item @click="logout">
						<v-list-item-icon>
							<v-icon>mdi-exit-to-app</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>Logout</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list>
			</template>
		</v-navigation-drawer>

		
	</nav>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'; 
import notifications from './NavigationNotifications.vue';
import image from "../../../public/logo.svg"


export default {
	data() {
		return{
			image: image,
			drawer: true,
			mini: true,
			links: [],
			rpt_links: [],
			trx_links: [],
			searchValue: '',
			logsData: [],
			links_data: [
				{ icon: 'fa-calendar-alt', text: 'Accounting Period', route: '/acctperiodmain'},
				{ icon: 'fa-list', text: 'Acknowledgement Receipt', route: '/acknowledgement_receipt'},
				{ icon: 'fa-user-check', text: 'Alphanumeric Tax Code', route: '/alphataxcode'},
				{ icon: 'fa-user-check', text: 'Approving Levels', route: '/approvinglevels'},
				{ icon: 'fa-user-check', text: 'Approving Officers', route: '/approvingofficers'},
				{ icon: 'fa-university', text: 'Banks', route: '/banks'},
				{ icon: 'fa-briefcase', text: 'Bank Headers', route: '/bankheader'},
				{ icon: 'fa-university', text: 'BP Banks', route: '/bpbanks'},
				{ icon: 'fa-map-marked', text: 'BP Locations', route: '/bplocations'},
				{ icon: 'fa-network-wired', text: 'Branches', route: '/branches'},
				{ icon: 'fa-handshake', text: 'Business Partners', route: '/businesspartners'},
				{ icon: 'fa-chart-bar', text: 'Chart of Account', route: '/coa'},
				{ icon: 'fa-building', text: 'Company', route: '/companies'},
				{ icon: 'fa-coins', text: 'Currencies', route: '/currency'},
				{ icon: 'fa-hotel', text: 'Departments', route: '/depts'},
				{ icon: 'fa-donate', text: 'Dollar Rates', route: '/dollarrate'},
				{ icon: 'fa-book', text: 'Modules', route: '/module'},
				{ icon: 'fa-receipt', text: 'Official Receipt', route: '/official_reciept'},
				{ icon: 'fa-user-friends', text: 'Payee', route: '/payefile'},
				{ icon: 'fa-chalkboard-teacher', text: 'Regional Head', route: '/regionalhead'},
				{ icon: 'fa-file-alt', text: 'Regular Transactions', route: '/regulartxns'},
				// { icon: 'fa-user-tag', text: 'Roles', route: '/roles'},
				{ icon: 'fa-universal-access', text: 'Role Access', route: '/roleaccess'}, 
				{ icon: 'fa-toolbox', text: 'Billing Services', route: '/services'}, 
				{ icon: 'fa-file', text: 'Transaction Type', route: '/transaction'},
				{ icon: 'fa-user', text: 'Users', route: '/users'},
				{ icon: 'fa-bars', text: 'Voucher Series', route: '/voucher'},
				{ icon: 'fa-receipt', text: 'Billing Invoice', route: '/billing-invoice'},
				{ icon: 'fa-user-check', text: 'Billing Officers', route: '/billing-officers'},
				
				// { icon: 'fa-hotel', text: 'Sections', route: '/sections'},    
				// { icon: 'folder', text: 'My Projects', route: '/projects'},
				// { icon: 'person', text: 'Team', route: '/team'}
			],
			trx_links_data: [
				{ icon: 'fa-book-open', text: 'Active Transactions', route: '/trx-active'},
				{ icon: 'fa-archive', text: 'Archived Transactions', route: '/trx-archive'},
				{ icon: 'fa-money-check', text: 'Billing', route: '/trx-billing'},
				{ icon: 'fa-file-invoice', text: 'Cash Receipts', route: '/trxcr'},
				{ icon: 'fa-newspaper', text: 'Disbursement', route: '/trxdisb'},
				{ icon: 'fa-book', text: 'Journal Entries', route: '/trx-gen-journal'},
				{ icon: 'fa-file-upload', text: 'Upload from IS', route: '/trx-upload-is'},
				{ icon: 'fa-money-check', text: 'Collection', route: '/trx-collection'},
				{ icon: 'fa-store-alt', text: 'Purchase Order', route: '/trx-purchase-order'},
				{ icon: 'fa-credit-card', text: 'Payment', route: '/trx-payment'}
			],
			rpt_links_data: [
				{ icon: 'fa-file-alt', text: 'Profitability', route: '/profitability'},
				{ icon: 'fa-file-alt', text: 'Subsidiary', route: '/subsidiary'},
				{ icon: 'fa-file-alt', text: 'Subsidiary Analysis', route: '/subsidiary-analysis'},
				{ icon: 'fa-chart-bar', text: 'Trial Balance', route: '/trial-balance'},
				{ icon: 'fa-chart-bar', text: 'AR Aging', route: '/ar-aging'},
				{ icon: 'fa-file-alt', text: 'Billing List', route: '/billing-list'},
				// { icon: 'fa-network-wired', text: 'General Ledger', route: '/branches'},
				// { icon: 'fa-hotel', text: 'Balance Sheet', route: '/depts'},
			],
			nav_user_role: '',
			user_type: [],
		};
	},
	components:{
        notifications: notifications,
    },
	computed: {
		...mapGetters({
			currUser: 'auth/currUser',
			token: 'auth/token',
			roleaccess: 'auth/roleaccess',
			isAuthenticated: 'auth/isAuthenticated',
			userLoggedIn: 'auth/getUser',
			profile: 'auth/profile',
			logsDialog: 'salesProspecting/logsDialog'
		}),
	},
	created()
	{
		console.log('User:')
		console.log(this.currUser)

		let role = this.currUser.user_role.charAt(0).toUpperCase() + this.currUser.user_role.slice(1)

		console.log(role)
		

		this.nav_user_role = role
		if (this.currUser.is_avp == 1){
			this.user_type.push('AVP')
		}
		if (this.currUser.is_control_committee == 1){
			this.user_type.push('Control Committee')
		}
		if (this.currUser.is_det_compliance_approver == 1){
			this.user_type.push('DET Compliance')
		}
		if (this.currUser.is_det_encoder == 1){
			this.user_type.push('DET Encoder')
		}
		if (this.currUser.is_evaluation_committee == 1){
			this.user_type.push('Evaluation Committee')
		}
		if (this.currUser.is_onboarding_committee == 1){
			this.user_type.push('Onboarding Committee')
		}
		if (this.currUser.is_president == 1){
			this.user_type.push('President')
		}
		if (this.currUser.is_sas_committee == 1){
			this.user_type.push('SOS Committee')
		}
		if (this.currUser.is_validation_committee == 1){
			this.user_type.push('Validation Committee')
		}
		if (this.currUser.is_oh_approver == 1){
			this.user_type.push('Onboarding Head')
		}
		if (this.currUser.is_finance_committee == 1){
			this.user_type.push('Finance Committee')
		}

		if (this.user_type.length == 0){
			this.user_type.push('None')
		}
	},
	methods: {
		async viewLogs(){
			const res = await this.$store.dispatch('salesProspecting/doGetLogs')
			this.logsData = res.data.result
			this.$store.commit('salesProspecting/SET_LOGS_DIALOG', true)
		},
		openWindow (url, title, options = {}) {
			if (typeof url === 'object') {
				options = url;
				url = '';
			}
			options = { url, title, width: 600, height: 720, ...options };
		
			const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screen.left;
			const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screen.top;
			const width = window.innerWidth || document.documentElement.clientWidth || window.screen.width;
			const height = window.innerHeight || document.documentElement.clientHeight || window.screen.height;
		
			options.left = ((width / 2) - (options.width / 2)) + dualScreenLeft;
			options.top = ((height / 2) - (options.height / 2)) + dualScreenTop;
		
			const optionsStr = Object.keys(options).reduce((acc, key) => {
				acc.push(`${key}=${options[key]}`);
				return acc;
			}, []).join(',');
		
			const newWindow = window.open(url, title, optionsStr);
		
			if (window.focus) {
				newWindow.focus();
			}
			return newWindow;
		},
		avatarColor (fullname, ligthness = 40, saturation = 75) {
			let hash = 0

			if (fullname) {
				for (let i = 0; i < fullname.length; i++) {
				hash = fullname.charCodeAt(i) + ((hash << 5) - hash)
				}
				const hue = hash % 360
				return `background-color: hsl(${hue}, ${saturation}%, ${ligthness}%); color:white; !important`
			}
			return ''
		},
		avatarInitial (fullname)  {
			let initial = ''

			if (fullname) {
				let name = ''

				fullname = fullname.toUpperCase()

				if (fullname.indexOf(',') !== -1) {
				fullname = fullname.split(',')
				name = fullname[1]?.replace(/(^\s+|\s+$)/g, '')
				} else {
				name = fullname
				}

				initial = name.substr(0, 1)
			}
			return initial
		},
			...mapActions({
				doLogout: 'auth/doLogout',
				// getCurrentUser: 'auth/getCurrentUser',
			}),
			toggleMini() {
				return this.mini = !this.mini;
			},
			// Okta Logout

			// async logout(){
			// 	const token = localStorage.getItem('auth-data')
			// 	const newToken = JSON.parse(token)
			// 	const payload = newToken.okta.id_token
			// 	const res = await this.$store.dispatch('auth/doLogout', payload)
			// 	const newWindow = this.openWindow('', 'message');
			// 	const urlAccountSelection = res.data.result;
			// 	newWindow.location.href = urlAccountSelection
			// 	if(res.status === 200) {
			// 		this.$store.commit('auth/UNSET_AUTH_TIMESTAMP');
			// 		this.$router.push('/');
			// 		location.reload();

			// 	}
			// },

			// Google Logout

			// async logout(){
			// 	const res = await this.$store.dispatch('auth/doGoogleLogout')
			// 	if(res.status === 200) {
			// 		this.$store.commit('auth/UNSET_AUTH_TIMESTAMP');
			// 		this.$router.push('/');
			// 		location.reload();
			// 	}
			// },
			
			// Okta and Google Logout
			async logout(){
				const token = localStorage.getItem('auth-data')
				const newToken = JSON.parse(token)
				if (newToken.okta){
					console.log('Okta Logout')
					const token = localStorage.getItem('auth-data')
					const newToken = JSON.parse(token)
					const payload = newToken.okta.id_token
					const res = await this.$store.dispatch('auth/doLogout', payload)
					const newWindow = this.openWindow('', 'message');
					const urlAccountSelection = res.data.result;
					newWindow.location.href = urlAccountSelection
					if(res.status === 200) {
					this.$store.commit('auth/UNSET_AUTH_TIMESTAMP');
					this.$router.push('/');
					location.reload();
					}
				} else if (newToken.google){
					console.log('Google Logout')
					const res = await this.$store.dispatch('auth/doGoogleLogout')
					if(res.status === 200) {
					this.$store.commit('auth/UNSET_AUTH_TIMESTAMP');
					this.$router.push('/');
					location.reload();
					}
				}
			},
			routeTo (pRouteTo) {
				if (this.breadcrumbList[pRouteTo].link) this.$router.push(this.breadcrumbList[pRouteTo].link);
			},
			getLinks () {
				let links = [];
				let roles = {};
				roles = this.currUser.roleaccess;

				if(roles.length > 0){
					this.links = [];
					this.rpt_links = [];
					this.trx_links = [];
					roles.sort((a,b) => (a.module_name > b.module_name) ? 1 : ((b.module_name > a.module_name) ? -1 : 0));
					roles.map(det => {
						if(det.module_type === 'F'){
							let link = this.links_data.filter(data => (data['text'] === det.module_name));
							if(link.length > 0){
								links.push(link[0]);
							}
						}
					});
				}

				return links;
			},
			getTrxLinks () {
				let links = [];
				let roles = {};
				roles = this.currUser.roleaccess;

				if(roles.length > 0){
					this.links = [];
					this.rpt_links = [];
					this.trx_links = [];
					roles.sort((a,b) => (a.module_name > b.module_name) ? 1 : ((b.module_name > a.module_name) ? -1 : 0));
					roles.map(det => {
						if(det.module_type === 'T'){
							let link = this.trx_links_data.filter(data => (data['text'] === det.module_name));
							if(link.length > 0){
								links.push(link[0]);
							}
						}
					});
				}

				return links;
			},
			getRptLinks () {
				let links = [];
				let roles = {};
				roles = this.currUser.roleaccess;

				if(roles.length > 0){
					this.links = [];
					this.rpt_links = [];
					this.trx_links = [];
					roles.sort((a,b) => (a.module_name > b.module_name) ? 1 : ((b.module_name > a.module_name) ? -1 : 0));
					roles.map(det => {
						if(det.module_type === 'R'){
							let link = this.rpt_links_data.filter(data => (data['text'] === det.module_name));
							if(link.length > 0){
								links.push(link[0]);
							}
						}
					});
				}

				return links;
			}
		},
};
</script>

<style scoped>
	.appbar-title {
		width: 180px;
		text-align: left;
	}
	.custom-color {
		background: linear-gradient(9.2deg, #1A2791 72.76%, #6633CC 98.69%);
	}
	.custom-color-logo {
		background: linear-gradient(9.2deg, #1A2791 40.76%, #6633CC 98.69%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		font-weight: bold;
		font-size: 20px;
	}
	img {
		width: 60px;
	}
	.picture-icon {
		width: 35px;
		height: 35px;
		border-radius: 50%;
	}
</style>