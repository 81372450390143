import { RepositoryFactory } from '../../../api_repo/RepositoryFactory';

const accountManagementRepository = RepositoryFactory.get('accountManagementRepository');

const doGetListEncoder = ({commit}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		accountManagementRepository.getListEncoder()
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});


export default{
	doGetListEncoder
};