// Store functionality
import Vue from 'vue';
import Vuex from 'vuex';
import actions from './actions';
import getters from './getters';
import modules from './modules';
import mutations from './mutations';
import state  from './state';
import createPersitedState from 'vuex-persistedstate';
import SecureLS from 'secure-ls';
import VueCookies from 'vue-cookies';

const ls = new SecureLS({ isCompression: false });

Vue.use(Vuex);
Vue.use(VueCookies);

// Create a new store
const store = new Vuex.Store({
	actions,
	getters,
	mutations,
	modules,
	state,
	plugins: [
		createPersitedState({
			paths: [
				'salesProspecting.spNumber',
				'auth.currUser',
				'auth.authTimestamp',
				'auth.profile',
				'bsp.bspId'
			],
			storage: {
				getItem: (key) => ls.get(key),
				setItem: (key, value) => ls.set(key, value),
				removeItem: (key) => ls.remove(key)
			}
		})
	]
});

export default store;
