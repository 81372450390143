import { RepositoryFactory } from '../../../api_repo/RepositoryFactory';
// import router from '../../../router';
// import Vue from 'vue';

const scoreCardRepository = RepositoryFactory.get('scoreCard');

const doGetScoreCard = (context) => new Promise((resolve,reject) => {
	context.commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		scoreCardRepository.getScoreCard()
			.then((response) => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch(() => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				reject();
			});
	}, 2000);
});

const doUpdateScoreCard = (context, payload) => new Promise((resolve,reject) => {
	context.commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		scoreCardRepository.updateScoreCard(payload)
			.then((response) => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				context.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Your work has been successful! ','messagetype':'success'},{ root: true });
				resolve(response);
			})
			.catch(() => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				reject();
			});
	}, 2000);
});

export default{
	doGetScoreCard,	
	doUpdateScoreCard
};