const SET_CURRENT_USER = (state, payload) => {
	// console.log(payload, 'from mutations')
	state.currUser = payload;
};

const AUTH_REQUEST = (state) => {
	state.status = 'loading';
	state.isLoading = true;
};

const AUTH_REQUEST_EMAIL = (state) => {
	state.status = 'loading';
	state.isLoadingEmail = true;
};
const AUTH_REQUEST_OKTA = (state) => {
	state.status = 'loading';
	state.isLoadingOkta = true;
};


const AUTH_SUCCESS_OKTA = (state, token) => {
	state.status = 'success';
	state.token = token.okta;
	state.hris_token = token.hris_token;
	state.isAuthenticated = true;
	state.currUser = token.user_details;
	state.isLoadingOkta = false;
};

const AUTH_ERROR = (state) => {
	state.isAuthenticated = false;
	state.isLoading = false;
	state.isLoadingEmail = false;
	state.isLoadingOkta = false;
	state.error = false;
};

const AUTH_LOGOUT = (state) => {
	state.isAuthenticated = false;
	state.currUser = {}
};

const SET_ISLOADING = (state, payload) => {
	state.isLoading = payload;
};

const SET_SHOWRESULT = (state, payload) => {
	state.showResult = payload;
};

const SET_ISAUTHENTICATED = (state, payload) => {
	state.isAuthenticated = payload;
};

const SET_RUN_SESSION = (state, payload) => {
	state.runSession = payload
};

const SET_AUTH_TIMESTAMP = (state, payload) => {
	state.authTimestamp = payload
};

const SET_SHOW_REFRESH = (state, payload) => {
	state.showRefreshPrompt = payload
}

const SET_HRIS_TOKEN = (state, payload) => {
	state.hrisToken = payload
}

const UNSET_AUTH_TIMESTAMP = (state) => {
	state.authTimestamp = {}
};

const SET_PROFILE_PIC = (state, payload) => {
	state.profile = payload
}

export default {
	AUTH_REQUEST,
	AUTH_ERROR,
	SET_CURRENT_USER,
	AUTH_LOGOUT,
	SET_ISLOADING,
	SET_SHOWRESULT,
	SET_ISAUTHENTICATED,
	AUTH_REQUEST_EMAIL,
	AUTH_SUCCESS_OKTA,
	AUTH_REQUEST_OKTA,
	SET_RUN_SESSION,
	SET_AUTH_TIMESTAMP,
	SET_SHOW_REFRESH,
	SET_HRIS_TOKEN,
	UNSET_AUTH_TIMESTAMP,
	SET_PROFILE_PIC
};