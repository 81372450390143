export default{
    imports : {
        charges: {
            file: null
        },
        commissions_trail: {
            file: null
        },
        commissions: {
            file: null
        },
        bank: {
            file: null
        },
        email: {
            file: null
        },
        manpower: {
            file: null
        },
    },
    isDetails: [],
    disable: false
}