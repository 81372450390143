import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import axios from 'axios';
import VueAxios from 'vue-axios';
import store from './store';
import './plugins/chartist.js'; 
import VueCookies from 'vue-cookies';
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue';

Vue.config.productionTip = false;

import * as VueGoogleMaps from 'vue2-google-maps'
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCG9aCdQH0K4WnnWdFBLr6zDLc8OLQ-5rQ',
    libraries: 'places',
  }
})


Vue.use(VueAxios, axios);
Vue.use(VueCookies);
Vue.use(VueTimepicker);

Vue.config.productionTip = false;  
Vue.use(VueAxios, axios);
new Vue({
	router,
	store,
	vuetify, 
	render: h => h(App)
}).$mount('#app');
