const SET_DRAWER = (state,payload) => {
	state.drawer = payload;
};

const SET_COLOR = (state,payload) => {
	state.color = payload;
};

const SET_SNACK_BAR = (state, payload) => {
	state.shown = payload.status;
	state.message = payload.message;
	state.messagetype = payload.messagetype;
};

const SET_IS_LOADING = (state,payload) => {
	state.isLoading = payload;
};

const SET_IS_LOADING_DASHBOARD = (state,payload) => {
	state.isLoadingDashboard = payload;
};

export default {
	SET_DRAWER,
	SET_COLOR,
	SET_SNACK_BAR,
	SET_IS_LOADING,
	SET_IS_LOADING_DASHBOARD
};