<template>
  <v-card
    class="mx-auto"
    width="500"
    max-width="500"
  >

  <v-toolbar
        color="primary"
        dark
        height="50px"
    >

        <v-toolbar-title>Notifications</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon>
        <v-icon>mdi-chat-alert-outline</v-icon>
        </v-btn>
    </v-toolbar>
  
    <v-list two-line class="scrollable-list">
      <v-list-item-group
        active-class="blue--text"
        multiple
      >

        <template v-for="(item, index) in items">
          <v-list-item :key="item.title" @click="updateStatus(item)">
            <template v-slot:default="{ }">

              <v-list-item-content>

                <v-card-text class="">
                <h3 class="pb-2">{{item.title}}</h3>
                <p>{{item.description}}</p>
                </v-card-text>

              </v-list-item-content>

              <v-list-item-action>
                <v-list-item-action-text >{{item.status}}</v-list-item-action-text>

                <v-icon
                  v-if="item.status == 'New'"
                  color="red"
                >
                  mdi-progress-alert
                </v-icon>

                <v-icon
                  v-else
                  color="green"
                >
                  mdi-progress-check
                </v-icon>
              </v-list-item-action>

            </template>
          </v-list-item>

          <v-divider
            v-if="index < items.length - 1"
            :key="index"
          ></v-divider>

        </template>
        
      </v-list-item-group>
    </v-list>
  </v-card>
</template>

<script>
  export default {
    data: () => ({
      items: [
        {
          status: 'Read',
          description: `This feature is not available yet`,
          title: 'PRP Announcement',
        },
      ],
    }),
    methods:{
        updateStatus(e){
            console.log(e)
        }
    }
  }
</script>

<style scoped>
.scrollable-list {
  max-height: 600px; /* Adjust max-height as needed */
  overflow-y: auto;
}
.scrollable-list::-webkit-scrollbar {
  display: none;
}
</style>