import { RepositoryFactory } from '../../../api_repo/RepositoryFactory';
// import router from '../../../router';
// import Vue from 'vue';

const emailRepository = RepositoryFactory.get('email');

const doGetRecipients = (context) => new Promise((resolve,reject) => {
	context.commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		emailRepository.getMailRecipients()
			.then((response) => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch(() => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				reject();
			});
	}, 2000);
});

const doUpdateRecipients = (context, {payload}) => new Promise((resolve,reject) => {
	context.commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		emailRepository.updateMailRecipients({payload})
			.then((response) => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				context.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Your work has been successful! ','messagetype':'success'},{ root: true });
				resolve(response);
			})
			.catch(() => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				reject();
			});
	}, 2000);
});

export default{
	doGetRecipients,	
	doUpdateRecipients
};