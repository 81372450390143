const setAppDrawer = (context,payload) => {
	context.commit('SET_DRAWER',payload);
};

const setAppColor = (context,payload) => {
	context.commit('SET_COLOR',payload);
};

const setSnackBar = (context, payload) => {
	context.commit('SET_SNACK_BAR', payload);
};

const setIsLoading = (context, payload) => {
	context.commit('SET_IS_LOADING',payload);
};
const setIsLoadingDashboard = (context, payload) => {
	context.commit('SET_IS_LOADING_DASHBOARD',payload);
};

export default {
	setAppDrawer,
	setAppColor,
	setSnackBar,
	setIsLoading,
	setIsLoadingDashboard
};