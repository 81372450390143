<template>
  <div class="text-center">
    <v-dialog
      :value.sync="isLoading"
      persistent
      width="300"
    >
      <v-card
        light
        color="white"
      >
        
          <v-progress-linear
            rounded
            indeterminate
            color="primary"
          ></v-progress-linear>
      
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	computed: {
		...mapGetters({
			isLoading: 'app/isLoading'
		})
	},
	// watch: {
	//   isLoading(newVal,oldVal){
	//     console.log('NEW VALUE' + newVal + '     OLD VALUE: ' + oldVal)
	//   }
	// }
};
</script>