const isAuthenticated = state => state.isAuthenticated;
const currUser = state => state.currUser;
const roleAccess = state => state.roleAccess;
const authStatus = state => state.status;
const token = state => state.token;
const isLoading = state => state.isLoading;
const isLoadingEmail = state => state.isLoadingEmail;
const isLoadingOkta = state => state.isLoadingOkta;
const error = state => state.error;
const showResult = state => state.showResult;
const result = state => state.result;
const runSession = state => state.runSession
const authTimestamp = state => state.authTimestamp
const showRefreshPrompt = state => state.showRefreshPrompt
const hrisToken = state => state.hrisToken
const final_comment = state => state.final_comment
const avp = state => state.avp
const profile = state => state.profile

export default {
	isAuthenticated,
	currUser,
	authStatus,
	token,
	isLoadingEmail,
	isLoading,
	error,
	showResult,
	result,
	roleAccess,
	isLoadingOkta,
	runSession,
	authTimestamp,
	showRefreshPrompt,
	hrisToken,
	final_comment,
	avp,
	profile
};