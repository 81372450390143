import { RepositoryFactory } from '../../../api_repo/RepositoryFactory';
// import router from '../../../router';
// import Vue from 'vue';

const usersRepository = RepositoryFactory.get('users');

const doSearchUsers = (context) => new Promise((resolve,reject) => {
	context.commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		usersRepository.searchUsers()
			.then((response) => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch(() => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				reject();
			});
	}, 2000);
});

const getAllHrisUsers = (context,payload) => new Promise((resolve,reject) => {
	context.commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		usersRepository.allHrisUsers(payload)
			.then((response) => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch(() => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				reject();
			});
	}, 2000);
});

const addUsertoSam = (context,payload) => new Promise((resolve,reject) => {
	console.log(payload)
	context.commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		usersRepository.usertoSam(payload)
			.then((response) => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch(() => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				reject();
			});
	}, 2000);
});

const doToggleStatus = (context,payload) => new Promise((resolve,reject) => {
	console.log(payload)
	context.commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		usersRepository.toggleStatus(payload)
			.then((response) => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch(() => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				reject();
			});
	}, 2000);
});

const doUpdateRole = (context,payload) => new Promise((resolve,reject) => {
	console.log(payload)
	context.commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		usersRepository.updateRole(payload)
			.then((response) => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				context.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Your work has been successful! ','messagetype':'success'},{ root: true });
				resolve(response);
			})
			.catch(() => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				reject();
			});
	}, 2000);
});

const doAddToOnboardingCommittee = (context,payload) => new Promise((resolve,reject) => {
	console.log(payload)
	context.commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		usersRepository.addToOnboardingCommittee(payload)
			.then((response) => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				context.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Your work has been successful! ','messagetype':'success'},{ root: true });
				resolve(response);
			})
			.catch(() => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				reject();
			});
	}, 2000);
});

const doAddToEvaluationCommittee = (context,payload) => new Promise((resolve,reject) => {
	console.log(payload)
	context.commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		usersRepository.addToEvaluationCommittee(payload)
			.then((response) => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				context.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Your work has been successful! ','messagetype':'success'},{ root: true });
				resolve(response);
			})
			.catch(() => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				reject();
			});
	}, 2000);
});

const doAddToAvpEncoder = (context,payload) => new Promise((resolve,reject) => {
	console.log(payload)
	context.commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		usersRepository.addToAvpEncoder(payload)
			.then((response) => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				context.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Your work has been successful! ','messagetype':'success'},{ root: true });
				resolve(response);
			})
			.catch(() => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				reject();
			});
	}, 2000);
});

const doAddAsPresident = (context,payload) => new Promise((resolve,reject) => {
	console.log(payload)
	context.commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		usersRepository.addAsPresident(payload)
			.then((response) => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				context.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Your work has been successful! ','messagetype':'success'},{ root: true });
				resolve(response);
			})
			.catch(() => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				reject();
			});
	}, 2000);
});

const doRemoveAsPresident = (context,payload) => new Promise((resolve,reject) => {
	console.log(payload)
	context.commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		usersRepository.removeAsPresident(payload)
			.then((response) => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				context.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Your work has been successful! ','messagetype':'success'},{ root: true });
				resolve(response);
			})
			.catch(() => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				reject();
			});
	}, 2000);
});

const doRemoveFromOnboardingCommittee = (context,payload) => new Promise((resolve,reject) => {
	console.log(payload)
	context.commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		usersRepository.removeFromOnboardingCommittee(payload)
			.then((response) => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				context.commit('app/SET_SNACK_BAR',{'status':true,'message': 'The User has been removed from the Onboarding Committee! ','messagetype':'success'},{ root: true });
				resolve(response);
			})
			.catch(() => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				reject();
			});
	}, 2000);
});

const doRemoveFromEvaluationCommittee = (context,payload) => new Promise((resolve,reject) => {
	console.log(payload)
	context.commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		usersRepository.removeFromEvaluationCommittee(payload)
			.then((response) => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				context.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Your work has been successful! ','messagetype':'success'},{ root: true });
				resolve(response);
			})
			.catch(() => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				reject();
			});
	}, 2000);
});

const doRemoveFromAvpEncoder = (context,payload) => new Promise((resolve,reject) => {
	console.log(payload)
	context.commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		usersRepository.removeFromAvpEncoder(payload)
			.then((response) => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				context.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Your work has been successful! ','messagetype':'success'},{ root: true });
				resolve(response);
			})
			.catch(() => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				reject();
			});
	}, 2000);
});

const doAddAsValidation = (context,payload) => new Promise((resolve,reject) => {
	console.log(payload)
	context.commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		usersRepository.addAsValidation(payload)
			.then((response) => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				context.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Your work has been successful! ','messagetype':'success'},{ root: true });
				resolve(response);
			})
			.catch(() => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				reject();
			});
	}, 2000);
});

const doAddAsDetEncoder = (context,payload) => new Promise((resolve,reject) => {
	console.log(payload)
	context.commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		usersRepository.addAsDetEncoder(payload)
			.then((response) => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				context.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Your work has been successful! ','messagetype':'success'},{ root: true });
				resolve(response);
			})
			.catch(() => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				reject();
			});
	}, 2000);
});

const doAddAsCompliance = (context,payload) => new Promise((resolve,reject) => {
	console.log(payload)
	context.commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		usersRepository.addAsCompliance(payload)
			.then((response) => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				context.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Your work has been successful! ','messagetype':'success'},{ root: true });
				resolve(response);
			})
			.catch(() => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				reject();
			});
	}, 2000);
});

const doAddAsControlCommittee = (context,payload) => new Promise((resolve,reject) => {
	console.log(payload)
	context.commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		usersRepository.addAsControlCommittee(payload)
			.then((response) => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				context.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Your work has been successful! ','messagetype':'success'},{ root: true });
				resolve(response);
			})
			.catch(() => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				reject();
			});
	}, 2000);
});

const doAddAsSasCommittee = (context,payload) => new Promise((resolve,reject) => {
	console.log(payload)
	context.commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		usersRepository.addAsSasCommittee(payload)
			.then((response) => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				context.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Your work has been successful! ','messagetype':'success'},{ root: true });
				resolve(response);
			})
			.catch(() => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				reject();
			});
	}, 2000);
});

const doRemoveAsValidation = (context,payload) => new Promise((resolve,reject) => {
	console.log(payload)
	context.commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		usersRepository.removeAsValidation(payload)
			.then((response) => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				context.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Your work has been successful! ','messagetype':'success'},{ root: true });
				resolve(response);
			})
			.catch(() => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				reject();
			});
	}, 2000);
});

const doRemoveCompliance = (context,payload) => new Promise((resolve,reject) => {
	console.log(payload)
	context.commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		usersRepository.removeAsCompliance(payload)
			.then((response) => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				context.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Your work has been successful! ','messagetype':'success'},{ root: true });
				resolve(response);
			})
			.catch(() => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				reject();
			});
	}, 2000);
});

const doRemoveAsControlCommittee = (context,payload) => new Promise((resolve,reject) => {
	console.log(payload)
	context.commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		usersRepository.removeAsControlCommittee(payload)
			.then((response) => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				context.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Your work has been successful! ','messagetype':'success'},{ root: true });
				resolve(response);
			})
			.catch(() => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				reject();
			});
	}, 2000);
});

const doRemoveAsSasCommittee = (context,payload) => new Promise((resolve,reject) => {
	console.log(payload)
	context.commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		usersRepository.removeAsSasCommittee(payload)
			.then((response) => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				context.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Your work has been successful! ','messagetype':'success'},{ root: true });
				resolve(response);
			})
			.catch(() => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				reject();
			});
	}, 2000);
});

const doRemoveDetEncoder = (context,payload) => new Promise((resolve,reject) => {
	console.log(payload)
	context.commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		usersRepository.removeDetEncoder(payload)
			.then((response) => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				context.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Your work has been successful! ','messagetype':'success'},{ root: true });
				resolve(response);
			})
			.catch(() => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				reject();
			});
	}, 2000);
});

const doAddAsOhHead = (context,payload) => new Promise((resolve,reject) => {
	console.log(payload)
	context.commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		usersRepository.addAsOhHead(payload)
			.then((response) => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				context.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Your work has been successful! ','messagetype':'success'},{ root: true });
				resolve(response);
			})
			.catch(() => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				reject();
			});
	}, 2000);
});


const doRemoveAsOhHead = (context,payload) => new Promise((resolve,reject) => {
	console.log(payload)
	context.commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		usersRepository.removeAsOhHead(payload)
			.then((response) => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				context.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Your work has been successful! ','messagetype':'success'},{ root: true });
				resolve(response);
			})
			.catch(() => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				reject();
			});
	}, 2000);
});

const doAddAsFinanceCommittee = (context,payload) => new Promise((resolve,reject) => {
	console.log(payload)
	context.commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		usersRepository.addAsFinanceCommittee(payload)
			.then((response) => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				context.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Your work has been successful! ','messagetype':'success'},{ root: true });
				resolve(response);
			})
			.catch(() => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				reject();
			});
	}, 2000);
});

const doRemoveFinanceCommittee = (context,payload) => new Promise((resolve,reject) => {
	console.log(payload)
	context.commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		usersRepository.removeFinanceCommittee(payload)
			.then((response) => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				context.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Your work has been successful! ','messagetype':'success'},{ root: true });
				resolve(response);
			})
			.catch(() => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				reject();
			});
	}, 2000);
});

export default{
	doSearchUsers,
	doToggleStatus,
	doUpdateRole,
	doAddToOnboardingCommittee,
	doAddToEvaluationCommittee,
	doRemoveFromOnboardingCommittee,
	doRemoveFromEvaluationCommittee,
	doAddAsPresident,
	doRemoveAsPresident,
	doAddAsValidation,
	doRemoveAsValidation,
	doAddToAvpEncoder,
	doRemoveFromAvpEncoder,
	getAllHrisUsers,
	addUsertoSam,
	doAddAsDetEncoder,
	doRemoveDetEncoder,
	doAddAsCompliance,
	doRemoveCompliance,
	doAddAsControlCommittee,
	doRemoveAsControlCommittee,
	doAddAsSasCommittee,
	doRemoveAsSasCommittee,
	doAddAsOhHead,
	doRemoveAsOhHead,
	doAddAsFinanceCommittee,
	doRemoveFinanceCommittee
};