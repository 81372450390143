import { RepositoryFactory } from '../../../api_repo/RepositoryFactory';

const financeRepository = RepositoryFactory.get('financeRepository');

const doGetFinanceList = ({commit}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		financeRepository.getFinanceList()
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doGetIsDetails = ({commit} , payload) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		financeRepository.getIsDetails(payload)
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const changePageBranch = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		financeRepository.pageBranch({payload})
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doUploadEmail = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		financeRepository.uploadEmail({payload})
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doDeleteEmail = ({commit}, payload) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		financeRepository.deleteEmail(payload)
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doUploadExcelFinance = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		financeRepository.uploadExcelFinance({payload})
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doUpdateBankDetails = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		financeRepository.updateBankDetails({payload})
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doDisapproveBank = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		financeRepository.disapproveBank({payload})
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doApproveBank = ({commit}, payload) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		financeRepository.approveBank(payload)
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doEncoderSubmit = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		financeRepository.encoderSubmit({payload})
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doApproveOnboarding = ({commit} , payload) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		financeRepository.approveOnboarding(payload)
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doDisapproveOnboarding = ({commit} , {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		financeRepository.disapproveOnboarding({payload})
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

export default{
    doUploadEmail,
	doGetFinanceList,
	doGetIsDetails,
	changePageBranch,
	doDeleteEmail,
	doUploadExcelFinance,
	doUpdateBankDetails,
	doDisapproveBank,
	doApproveBank,
	doEncoderSubmit,
	doApproveOnboarding,
	doDisapproveOnboarding
};