const SET_DEFAULT = (state) => {
    state.is_entity = {
        file: null
    }
    state.is_location = {
        file: null
    }
    state.isDetails = {}
    state.is_branch = []
    state.user_id = ''
}

const SET_IS_DETAILS = (state, payload) => {
    state.isDetails = payload
}

const SET_USER_ID = (state, payload) => {
    state.user_id = payload
}

const SET_IS_BRANCH = (state, payload) => {
    state.is_branch = payload
}


export default {
    SET_DEFAULT,
    SET_IS_DETAILS,
    SET_USER_ID,
    SET_IS_BRANCH
}