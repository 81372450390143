// import VueCookies from 'vue-cookies';

export default {
	// isAuthenticated: VueCookies.get('auth-data') || false,
	// token: VueCookies.get('auth-data') || {},
	// roleAccess: VueCookies.get('role-access') || {},
	isAuthenticated: localStorage.getItem('auth-data') ? true : false,
	token: JSON.parse(localStorage.getItem('auth-data')) || {},
	// hrisToken: JSON.parse(localStorage.getItem('auth-data')) || {},
	hrisToken: localStorage.getItem('hris-token') || '',
	// hrisToken: '',
	currUser : '',
	isLoading: false,
	isLoadingEmail: false,
	isLoadingOkta: false,
	error: false,
	showResult: false,
	result: '',
	runSession: false,
	showRefreshPrompt: false,
	authTimestamp: {
		timeLoggedIn: '',
		timeToExpire: ''
	},
	profile: ''
};