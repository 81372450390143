export default{
    is_entity: {
        file: null
    },
    is_location: {
        file: null
    },
    isDetails: {},
    user_id: '',
    is_branch: []
}