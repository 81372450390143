const SET_IMPORTS_DEFAULT = (state) => {
    state.imports = {
        charges: {
            file: null
        },
        commissions_trail: {
            file: null
        },
        commissions: {
            file: null
        },
        bank: {
            file: null
        },
        email: {
            file: null
        },
        manpower: {
            file: null
        },
    }
}
const SET_IS_DETAILS = (state, payload) => {
    state.isDetails = payload
}

const SET_IS_DEFAULT = (state) => {
    state.isDetails = []
}

const SET_VIEW = (state, payload) => {
    state.disable = payload
}
export default {
    SET_IMPORTS_DEFAULT,
    SET_IS_DETAILS,
    SET_IS_DEFAULT,
    SET_VIEW
}