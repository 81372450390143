import Repository from '../Repository';
import axios from 'axios';

const resource = '/login';

export default {
	login(payload) {
		return Repository.post(`${resource}`, payload);
	},

	verifyToken(payload) {
		return Repository.post(`${resource}`, payload);
	},

	get_current_user(){
		return Repository.post('getuser');
	},

	logout(payload){
		return Repository.post('okta/logout', {
			id_token: payload
		});
	},

	loginDev(payload){
		return Repository.post('/google/dev-login', {
			id_token: payload
		});
	},

	loginOkta() {
		return Repository.get('/okta/login');
	},
	loginGoogle() {
		return Repository.get('/google/login');
	},

	googleLogout() {
		return Repository.get('/google/logout');
	},

	logoutOkta (payload) {
		console.log('Okta logout')
		return axios.get(process.env.VUE_APP_OKTA_BASE_URL + '/oauth2/v1/logout?id_token_hint='+payload);
	},

	refreshToken(payload){
		return Repository.post('okta/refresh', {
			refresh_token: payload
		});
	},
	googleRefreshToken(payload){
		return Repository.post('google/refresh', {
			refresh_token: payload
		});
	},
};