<template>
	<v-breadcrumbs class="breadcrumb" :items="breadcrumbList">
		<template v-slot:divider>
			<v-icon color="black">mdi-chevron-right</v-icon>
		</template>
	</v-breadcrumbs>
</template>
<script>
export default {
	data () {
		return {
			breadcrumbList: [],
		};
	},
	mounted () { this.updateList(); },
	watch: { '$route' () { this.updateList(); } },
	methods: {
		updateList () { this.breadcrumbList = this.$route.meta.breadcrumb; }
	}
};
</script>
<style scoped>
	.breadcrumb-sam {
		font-family: 'Baloo Extra Bold';
		font-size: 24px;
		font-weight: bolder;
		margin: 0 auto;
		margin-bottom: 20px;
		color: #1A2791;
	}
</style>