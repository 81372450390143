import Repository from '../Repository';
// import axios from 'axios';

export default {
	getIs () {
        return Repository.get('/api/is-data/is-list')
    },
	uploadEntity ({payload}) {
        return Repository.post('/api/import/entity', payload.payload)
    },
	uploadLocation ({payload}) {
        return Repository.post('/api/import/location', payload.payload)
    },
    getIsDetails(payload) {
        return Repository.get('api/is-data/all/' + payload)
    },
    pageBranch ({payload}) {
		return Repository.get('/api/is-data/all/' + payload.id + '?page=' + payload.page)
	},
    mergeData({payload}) {
        return Repository.post('/api/is-data/merge/' + payload.bp_id_no, payload.finalPayload)
    },
    deleteBp(payload) {
        return Repository.delete('/api/is-data/' + payload)
    },
    mergeAll() {
        return Repository.get('/api/is-data/merge-all/')
    },
    getIsStandalone() {
        return Repository.get('/api/is-data/standalone/list')
    },
    uploadStandaloneLocation ({payload}) {
        return Repository.post('/api/import/standalone-location', payload.payload)
    },
    mergeAllStandalone() {
        return Repository.get('/api/is-data/standalone/merge-all/')
    },
    deleteStandaloneLocation(payload) {
        return Repository.delete('/api/is-data/standalone/' + payload)
    },
};