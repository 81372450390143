const SET_MERCHANT_BRANCH = (state, payload) => {
    state.merchantBranch = payload
}

const SET_MERCHANT_BRANCH_DEFAULT = (state) => {
    state.merchantBranch = []
    state.unenrolledMerchantBranch = []
}

const SET_MERCHANT_DEFAULT = (state) => {
    state.excel_file = {
        file: null
    }
}

const SET_UNENROLLED_MERCHANT_BRANCH = (state, payload) => {
    state.unenrolledMerchantBranch = payload
}

export default {
    SET_MERCHANT_BRANCH,
    SET_MERCHANT_BRANCH_DEFAULT,
    SET_MERCHANT_DEFAULT,
    SET_UNENROLLED_MERCHANT_BRANCH
}