<template>
	<v-app id="login" class="custom-color">
		<v-main>
			<v-container fluid fill-height>
				<v-layout align-center justify-center>
					<v-flex xs12 sm8 md4 lg4>
						<v-card class="elevation-4 pl-2 pr-2">
							<v-card-text>
								<v-row>
									<div class="container-div mt-5">
										<div class="center-image">
										<img src="../../public/logo.svg" width="200" height="150" />
										</div>
									</div>
								</v-row>
								
								<v-row v-if="environment == 'Local'">
									<v-col>
										<v-btn dark color="primary" :loading="loadingOkta" @click="loginOkta" width="260px"> 
											Log In With Okta Account
										</v-btn>
									</v-col>
								</v-row>

								<v-row v-if="environment == 'Production' || environment == 'Staging' || environment == 'Local'">
									<v-col>
										<v-btn dark color="primary" :loading="loadingOkta" @click="loginGoogle" width="260px"> 
											Log In With Google Account
										</v-btn>
									</v-col>
								</v-row>

								<v-row v-if="environment == 'Develop'">
									<v-col>
										<v-btn width="10px"> 
											<div id="g_id_onload"
												data-client_id="298701020374-h1hcugf74q98a78ve413cd4s4tj9quaq.apps.googleusercontent.com"
												data-context="signin"
												data-ux_mode="popup"
												data-callback="handleCredentialResponse"
												data-itp_support="true">
											</div>

											<div class="g_id_signin" data-type="standard"></div>
										</v-btn>
									</v-col>
								</v-row>

								<br>
								<br>
							</v-card-text>						
						</v-card>
					</v-flex>
				</v-layout>

				<v-row>
					<v-col
					class="footer"
					cols="2"
					>
						<v-footer padless>
							<img src="../../public/petnet.svg"/>
						</v-footer>
					</v-col>
				</v-row>
				
			</v-container>
			
		</v-main>
	</v-app>
</template>
<script>

import { mapGetters } from 'vuex';
import Vue from 'vue';

import GSignInButton from 'vue-google-signin-button'
Vue.use(GSignInButton)

export default {
	data: () => ({ 
		hidePassword: true,
		rules: {
			required: value => !!value || 'Required.'
		},
		user_id: '',
		password: '',
		base64String: '',
		token: '',
		isCaptchaVerified: true,
		company: [
			{value: '1', text: 'Petnet'},
			{value: '2', text: 'Better Way'},
			{value: '3', text: 'City Savings Bank'},
			{value: '4', text: 'Banana Pay'}
		],
		environment: process.env.VUE_APP_ENV,
		googleDevToken: null,
	}),
	computed: {
		...mapGetters({
			loading: 'auth/isLoading',
			loadingOkta: 'auth/isLoadingOkta',
			profile: 'auth/profile',
			error: 'auth/error'
		}),
	},
	mounted() {
		this.init();
		window.addEventListener('message', this.onMessage, false);
		
		if (this.environment == 'Develop') {
			window.addEventListener('google-dev-token-localstorage-changed', (event) => {
				this.googleDevToken = event.detail.storage;
				this.devLogin(this.googleDevToken)
			});
		}

		console.log('Sprint 18')

	},
	beforeMount() {
		window.removeEventListener('message', this.onMessage);
	},
	beforeDestroy() {
		window.removeEventListener('message', this.onMessage);
	},
	methods: {
		async devLogin(credential) {
			console.log('Dev Google Login')

			const res = await this.$store.dispatch('auth/doLoginDev', credential);

			const authData = {
				google: {
					id_token: credential
				},
				user_details: {
					email: res.data.email,
					is_active: res.data.is_active,
					is_evaluation_committee: res.data.is_evaluation_committee.toString(),
					is_onboarding_committee: res.data.is_onboarding_committee.toString(),
					is_president: res.data.is_president.toString(),
					is_validation_committee: res.data.is_validation_committee.toString(),
					is_det_encoder: res.data.is_det_encoder.toString(),
					is_det_compliance_approver: res.data.is_det_compliance_approver.toString(),
					is_avp: res.data.is_avp.toString(),
					is_control_committee: res.data.is_control_committee.toString(),
					is_sas_committee: res.data.is_sas_committee.toString(),
					is_oh_approver: res.data.is_oh_approver.toString(),
					is_finance_committee: res.data.is_finance_committee.toString(),
					name: res.data.name.toString(),
					user_role: res.data.user_role.toString()
				},
				token: res.data.api_token,
				hris_token: res.data.hris_token
			}

			if (res.status === 200) {
				localStorage.setItem('hris-token', res.data.hris_token);
				this.$store.commit('auth/SET_HRIS_TOKEN', res.data.hris_token)

				if (res.data.cluster_name || res.data.cluster_no) {
					authData.user_details.cluster_name =  res.data.cluster_name
					authData.user_details.cluster_no =  res.data.cluster_no
					authData.user_details.cluster_role =  res.data.cluster_role
					authData.user_details.cluster_head =  res.data.cluster_head
					authData.user_details.cluster_roh =  res.data.cluster_roh
					authData.user_details.cluster_sas =  res.data.cluster_sas
					authData.user_details.cluster_not_assigned =  res.data.cluster_not_assigned
					this.$store.dispatch('auth/setLoginOkta', authData)
				}
				else if (res.data.cluster_not_assigned == res.data.name){
					authData.user_details.cluster_name =  res.data.cluster_name
					authData.user_details.cluster_no =  res.data.cluster_no
					authData.user_details.cluster_role =  res.data.cluster_role
					authData.user_details.cluster_head =  res.data.cluster_head
					authData.user_details.cluster_roh =  res.data.cluster_roh
					authData.user_details.cluster_sas =  res.data.cluster_sas
					authData.user_details.cluster_not_assigned =  res.data.cluster_not_assigned
					this.$store.dispatch('auth/setLoginOkta', authData)
				}
				else {
					this.$store.dispatch('auth/setLoginOkta', authData)
				}

			} else if (res.code === 400) {
				this.$store.dispatch('app/setSnackBar',{'status': true,'message':'Invalid email account.','messagetype':'error'});
			} else if (res.code === 500) {
				this.$store.dispatch('app/setSnackBar',{'status': true,'message':'Invalid email account.','messagetype':'error'});
			}
		},
		onMessage (event) {
			const parentURL = process.env.VUE_APP_BASE_URL;
			if (event.origin == parentURL) {
				if (event.data.type === 'success') {
					// console.log(event)
					if(event.data.data.user_details){
						localStorage.setItem('hris-token', event.data.data.hris_token);
						this.$store.commit('auth/SET_HRIS_TOKEN', event.data.data.hris_token)
					}
					// SET AUTHDATA HERE
					this.$store.dispatch('auth/setLoginOkta', event.data.data);
					// SET PROFILE PIC
					this.$store.commit('auth/SET_PROFILE_PIC', event.data.data.google.avatar);

				} else {
					this.$store.commit('auth/AUTH_ERROR');
					this.$store.commit('app/SET_SNACK_BAR',{'status':true,'message': event.data.message ,'messagetype':'error'},{ root: true });
				}
			} else {
				this.$store.commit('auth/AUTH_ERROR');
			}
		},

		pdfConvert () {
			var decoded = Buffer.from(this.base64String, 'base64')
			var blob = new Blob([decoded], { type: 'application/pdf' })
			var fileURL = URL.createObjectURL(blob)
			window.open(fileURL)
			// FileSaver.saveAs(blob, 'filename')
		},
		loginOkta () {
			this.$store.dispatch('auth/doLoginWithOkta');
		},
		loginGoogle () {
			this.$store.dispatch('auth/doLoginWithGoogle');
		},
		init() {
			this.$store.dispatch('auth/setIsAuthenticated',false);			
		},
	}
};
</script>

<style scoped lang="css">
	#googleLogin {
	/* This is where you control how the button looks. Be creative! */
	display: inline-block;
	padding: 8px 30px;
	border-radius: 3px;
	color: #fff;
	}
	.g-signin-button:hover {
      cursor: pointer;
    }
  #login {
    height: 50%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
	text-align: center;
    content: "";
    z-index: 0;
    background: linear-gradient(9.2deg, #1A2791 72.76%, #6633CC 98.69%);
  }

  .secondary{
    background-image: url('https://cdn.vuetifyjs.com/images/parallax/material.jpg')
  }

  .petnet-logo {
    height: 80%;
    width: 30%;
	}

  .erp-logo {
    width: 70%;
	}

	.recaptcha {
		padding-left: 45px;
	}
	.btn {
    text-transform: unset !important;
	}
	.custom-color-logo {
		background: linear-gradient(9.2deg, #1A2791 40.76%, #6633CC 98.69%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		font-weight: bold;
		font-size: 20px;
	}
	.center-image {
		width: 200px;
	}
	.container-div {
		width: 100%;
		padding: 5px 0px;
	}
	.container-div .center-image{
		margin: 0 auto;
}
.footer {
	padding-left: 90%;
}
.footer{
	background: linear-gradient(9.2deg, #fff 72.76%, #fff 98.69%);
}
</style>