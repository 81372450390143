import { RepositoryFactory } from '../../../api_repo/RepositoryFactory';

const isRepository = RepositoryFactory.get('isRepository');

const doGetIs = ({commit}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		isRepository.getIs()
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doUploadEntity = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		isRepository.uploadEntity({payload})
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doUploadLocation = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		isRepository.uploadLocation({payload})
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doGetIsDetails = ({commit}, payload) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		isRepository.getIsDetails(payload)
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const changePageBranch = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		isRepository.pageBranch({payload})
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doMergeData = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		isRepository.mergeData({payload})
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doDeleteBp = ({commit}, payload) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		isRepository.deleteBp(payload)
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doMergeAll = ({commit}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		isRepository.mergeAll()
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});


const doGetIsStandalone = ({commit}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		isRepository.getIsStandalone()
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doUploadStandaloneLocation = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		isRepository.uploadStandaloneLocation({payload})
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doMergeAllStandalone = ({commit}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		isRepository.mergeAllStandalone()
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doDeleteStandaloneLocation = ({commit}, payload) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		isRepository.deleteStandaloneLocation(payload)
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

export default{
	doGetIs,
    doUploadEntity,
	doUploadLocation,
	doGetIsDetails,
	changePageBranch,
	doMergeData,
	doDeleteBp,
	doMergeAll,
	doGetIsStandalone,
	doUploadStandaloneLocation,
	doMergeAllStandalone,
	doDeleteStandaloneLocation
};