// import Vue from 'vue';
import axios from 'axios';
// import store from '../store/index';
// import router from 'vue-router';

const baseDomain = process.env.VUE_APP_BASE_URL;
const environment = process.env.VUE_APP_ENV;

const baseURL = `${baseDomain}`;

let config = {
	baseURL
};

let myAPI = axios.create(config);

myAPI.interceptors.request.use(
	(config) => {
		let authData = JSON.parse(localStorage.getItem('auth-data'));
		// let authData = Vue.$cookies.get('auth-data');
		// config.headers['Access-Control-Allow-Origin'] = '*';
		config.headers['Accept'] = 'application/json';
		config.headers['Content-Type'] = 'application/json';

		//Dev Header:
		if (environment == 'Develop'){
			config.headers['X-Perahub-Gateway-Token'] = 'ca40285ce31f0e6633e3a27837de726c';
		}
		

		if (authData) {

			config.headers['Authorization'] = `Bearer ${authData.token}`;

		}
		return config;
	}
);

// myAPI.interceptors.response.use(undefined, function (err) {
//     console.log(err.response.status);
//     return new Promise(function (resolve, reject) {
//         //console.log('1');
//          if (err.response.status === 401) {

//             // if you ever get an unauthorized, logout the user
//             this.$store.dispatch('auth/doLogOut')
//             // you can also redirect to /login if needed !
//             //this.$router.push('/login');
//             window.location('/login');
//         }
//         throw err;
//     });
// });

myAPI.interceptors.response.use(function (response) {
	return response;
}, function (error) {
	// let err = error.response.status;

	if (error.response && error.response.status === 0) {
        // CORS error occurred
        return error.response
    }

	let isLogin = error.response.config.url.indexOf('login');
	//console.log(err);
	if (isLogin > 0) {
		return error;
	}
	if (error.response.data){
		return error.response.data
	}
	// else {
	// 	if (err == 401) {
	// 		Vue.swal.fire({
	// 			title: 'Session Expired',
	// 			text: 'Your session has expired. Would you like to be redirected to the login page?',
	// 			type: 'warning',
	// 			showCancelButton: false,
	// 			confirmButtonColor: '#3085d6',
	// 			cancelButtonColor: '#d33',
	// 			confirmButtonText: 'Re-login'
	// 		}).then((result) => {
	// 			if (result.value) {
	// 				store.dispatch('auth/doLogout');
	// 				router.push('/login');
	// 				//window.location = '/login';                            
	// 			}
	// 		});

	// 	} else {
	// 		Vue.swal.fire({
	// 			type: 'error',
	// 			title: 'Failed getting data',
	// 			text: error.response.data.message,
	// 			timer: 2000
	// 		});
	// 		return Promise.reject(error);
	// 	}
	// }
});

export default myAPI;
