const bspId = state => state.bspId
const detDetails = state => state.detDetails
const detFilesUpload = state => state.detFilesUpload
const existingDetFiles = state => state.existingDetFiles
const comment = state => state.comment
const disable_encoder_view = state => state.disable_encoder_view
const disable_det_view = state => state.disable_det_view
const compliance = state => state.compliance
const complianceUpload = state => state.complianceUpload
const existingComplianceDocs = state => state.existingComplianceDocs

export default {
    bspId,
    detDetails,
    detFilesUpload,
    existingDetFiles,
    comment,
    disable_encoder_view,
    disable_det_view,
    compliance,
    complianceUpload,
    existingComplianceDocs
}