<template>
	<v-app light>
		<div v-if="!isAuthenticated">
			<v-app-login></v-app-login>
		</div>
		<div v-else-if="isAuthenticated">
			<v-app-navigation v-show="getShow($route.name)"></v-app-navigation>
			<v-app-loader v-show="getShow($route.name)"></v-app-loader>
			<v-app-content v-show="getShow($route.name)"></v-app-content>

			<router-view name="printRouter" />
		</div>
		<v-app-snackbar />
		<v-app-token-reminder />
	</v-app>
</template>
<script>

import Navigation from '@/components/partials/Navigation.vue';
import AppContent from './components/partials/AppContent.vue';
import LoginVue from './views/Login.vue';
import { mapGetters } from 'vuex';
import AppSnackBar from './components/partials/AppSnackBar.vue';
import AppLoader from './components/partials/AppLoader.vue'; 
import TokenReminder from './components/partials/TokenReminder.vue'; 

export default {
	name: 'App',
	data() {
		return	{
			expireChecker: {}
		};
	},
	watch: {
		runSession: {
			handler (value) {
				if (value === false) {
				clearInterval(this.expireChecker)
				} else {
				this.expireChecker = setInterval(this.checkTime, 5000)
				}
			},
			deep: true
		},
		authTimestamp: {
			handler () {
			},
			deep: true
		}
	},
	mounted () {
		setInterval(this.checkTime, 5000)
	},
	components: {
		'v-app-content': AppContent,
		'v-app-login': LoginVue,
		'v-app-snackbar': AppSnackBar,
		'v-app-loader': AppLoader,
		'v-app-navigation': Navigation,
		'v-app-token-reminder': TokenReminder
	},
	computed: {
		...mapGetters({
			isAuthenticated: 'auth/isAuthenticated',
			runSession: 'auth/runSession',
			authTimestamp: 'auth/authTimestamp'
		})
	},
	methods: {
		getShow(routeName){
			let route = ['preview-trx', 'preview-trxdisb', 'preview-cheque', 'preview-voucher','preview-receipt','preview-billing','preview-collection-or','preview-collection-ar','preview-OR','preview-AR', 'report-trialbalance', 'report-subsidiary','report-araging','report-araging-invoice','report-billing-list', 'report-subsidiary-analysis', 'report-profitability','purchase-order-print'];
			let show = true;
			route.forEach(data => {
				if(data == routeName){
					show = false;
				}
			});
			return show;
		},
		checkTime () {
			const currentTime = new Date()
			const expireTime = new Date(this.authTimestamp.timeToExpire)
			const loggedInTime = new Date(this.authTimestamp.timeLoggedIn)
			const actualExpireTime = new Date(loggedInTime.getTime() + (50 * 60 * 1000))

			const data = localStorage.getItem('auth-data')

			// if logged-in time exceeds 50 minutes

			if (currentTime.getTime() > actualExpireTime.getTime()) {
				this.logout()
			}

			// trigger reminder 2 mins before expiry

			if (this.$route.path != '/' && data != null){
				if (currentTime.getTime() > expireTime.getTime() - (1 * 60 * 1000)) {
					this.$store.commit('auth/SET_SHOW_REFRESH', true)
					this.$store.commit('auth/SET_RUN_SESSION', false)
					clearInterval(this.expireChecker)
				}
			}


		},
		// Okta Logout
		
		// async logout(){
		// 	console.log('APP LOGOUT')
		// 	const token = localStorage.getItem('auth-data')
		// 	const newToken = JSON.parse(token)
		// 	const payload = newToken.okta.id_token
		// 	const res = await this.$store.dispatch('auth/doLogout', payload)
		// 	const newWindow = this.openWindow('', 'message');
		// 	const urlAccountSelection = res.data.result;
		// 	newWindow.location.href = urlAccountSelection
		// 	if(res.status === 200) {
		// 		this.$store.commit('auth/UNSET_AUTH_TIMESTAMP');
		// 		this.$router.push('/');
		// 		location.reload();
		// 	}
		// },

		// Google Logout
		// async logout(){
		// 	const res = await this.$store.dispatch('auth/doGoogleLogout')
		// 	if(res.status === 200) {
		// 		this.$store.commit('auth/UNSET_AUTH_TIMESTAMP');
		// 		this.$router.push('/');
		// 		location.reload();
		// 	}
		// },
		// Okta and Google Logout
		async logout(){
			const token = localStorage.getItem('auth-data')
			const newToken = JSON.parse(token)
			if (newToken.okta){
				console.log('Okta Logout')
				const token = localStorage.getItem('auth-data')
				const newToken = JSON.parse(token)
				const payload = newToken.okta.id_token
				const res = await this.$store.dispatch('auth/doLogout', payload)
				const newWindow = this.openWindow('', 'message');
				const urlAccountSelection = res.data.result;
				newWindow.location.href = urlAccountSelection
				if(res.status === 200) {
					this.$router.push('/');
					this.$store.commit('auth/UNSET_AUTH_TIMESTAMP');
					this.$store.commit('auth/SET_SHOW_REFRESH', false)
					location.reload();
				}
			} else if (newToken.google){
				console.log('Google Logout')
				const res = await this.$store.dispatch('auth/doGoogleLogout')
				if(res.status === 200) {
					this.$router.push('/');
					this.$store.commit('auth/UNSET_AUTH_TIMESTAMP');
					this.$store.commit('auth/SET_SHOW_REFRESH', false)
					location.reload();
				}
			}
		},
		openWindow (url, title, options = {}) {
			if (typeof url === 'object') {
				options = url;
				url = '';
			}
			options = { url, title, width: 600, height: 720, ...options };
		
			const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screen.left;
			const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screen.top;
			const width = window.innerWidth || document.documentElement.clientWidth || window.screen.width;
			const height = window.innerHeight || document.documentElement.clientHeight || window.screen.height;
		
			options.left = ((width / 2) - (options.width / 2)) + dualScreenLeft;
			options.top = ((height / 2) - (options.height / 2)) + dualScreenTop;
		
			const optionsStr = Object.keys(options).reduce((acc, key) => {
				acc.push(`${key}=${options[key]}`);
				return acc;
			}, []).join(',');
		
			const newWindow = window.open(url, title, optionsStr);
		
			if (window.focus) {
				newWindow.focus();
			}
			return newWindow;
		},
	}
};
</script>

<style>
	body {
		background-color: #eeeeee !important;
	}

	.v-date-picker-table .v-btn {
		padding: 0;
	}
</style>
