import Repository from '../Repository';
// import axios from 'axios';

export default {
	getFinanceList () {
		return Repository.get('api/finance');
	},
	getIsDetails (payload) {
		return Repository.get('/api/finance/all/' + payload);
	},
	pageBranch ({payload}) {
		return Repository.get('/api/finance/all/' + payload.id + '?page=' + payload.page)
	},
	uploadEmail ({payload}) {
		return Repository.post('/api/finance/is-email/' + payload.id, payload.finalPayload)
	},
	deleteEmail (payload) {
		return Repository.delete('/api/finance/is-email/' + payload)
	},
	uploadExcelFinance ({payload}) {
		return Repository.post('/api/import/finance-import/' , payload.payload)
	},
	updateBankDetails({payload}) {
		return Repository.post('/api/finance/bank-request/'+ payload.id , payload.finalPayload)
	},
	disapproveBank({payload}) {
		return Repository.post('/api/finance/bank-disapprove/'+ payload.id , payload.payload)
	},
	approveBank(payload) {
		return Repository.post('/api/finance/bank-approve/'+ payload)
	},
	encoderSubmit({payload}) {
		return Repository.post('/api/finance/charge-commission-request/'+ payload.id , payload.payload)
	},
	approveOnboarding(payload) {
		return Repository.post('/api/finance/onb-approve/'+ payload)
	},
	disapproveOnboarding({payload}) {
		return Repository.post('/api/finance/onb-disapprove/'+ payload.id , payload.finalPayload)
	},
	
};