const is_entity = state => state.is_entity
const is_location = state => state.is_location
const isDetails = state => state.isDetails
const user_id = state => state.user_id
const is_branch = state => state.is_branch

export default {
    is_entity,
    is_location,
    isDetails,
    user_id,
    is_branch
}