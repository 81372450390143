import { RepositoryFactory } from '../../../api_repo/RepositoryFactory';

const merchantRepository = RepositoryFactory.get('merchantRepository');

const doGetListEncoder = ({commit}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		merchantRepository.getListEncoder()
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doSubmitToOnboarding = ({commit}, payload) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		merchantRepository.submitToOnboarding(payload)
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doCheckSp = ({commit}, payload) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		merchantRepository.checkSp(payload)
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doGetListOnboarding = ({commit}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		merchantRepository.getListOnboarding()
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doUploadExcel = ({commit}, {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		merchantRepository.uploadExcel({payload})
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doGetFlaList = ({commit} , payload) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		merchantRepository.getFlaList(payload)
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doSubmitMerchantEmail = ({commit} , {payload}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		merchantRepository.submitMerchantEmail({payload})
			.then((response) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

export default{
	doGetListEncoder,
	doSubmitToOnboarding,
	doCheckSp,
	doGetListOnboarding,
	doUploadExcel,
	doGetFlaList,
	doSubmitMerchantEmail
};