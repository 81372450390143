const spNumber = state => state.spNumber
const salesProspectingForm = state => state.salesProspectingForm
const documents = state => state.documents
const selectedSalesProspectingForm = state => state.selectedSalesProspectingForm
const branches = state => state.branches
const moa = state => state.moa
const existingUploadedFiles = state => state.existingUploadedFiles
const existingUploadedOwners = state => state.existingUploadedOwners
const existingUploadedBranches = state => state.existingUploadedBranches
const owners = state => state.owners
const cities = state => state.cities
const countries = state => state.countries
const primaryBusinessAddress = state => state.primaryBusinessAddress
const complianceOfficer = state => state.complianceOfficer
const primaryContactInfo = state => state.primaryContactInfo
const otherPrimaryDetails = state => state.otherPrimaryDetails
const primaryDetails = state => state.primaryDetails
const existingInitialUploaded = state => state.existingInitialUploaded
const initialUploaded = state => state.initialUploaded
const genericRule = state => state.genericRule
const checkboxRule = state => state.checkboxRule
const scoreCardRule = state => state.scoreCardRule
const emailRule = state => state.emailRule
const fileRule = state => state.fileRule
const comment = state => state.comment
const setComments = state => state.setComments
const bsp = state => state.bsp
const existingBSP = state => state.existingBSP
const salesProspectingFormExisting = state => state.salesProspectingFormExisting
const bspRule = state => state.bspRule
const existingGenericRule = state => state.existingGenericRule
const existingEmailRule = state => state.existingEmailRule
const existingFileRUle = state => state.existingFileRUle
const single_proprietor_upload = state => state.single_proprietor_upload
const bod = state => state.bod
const existingUploadedBod = state => state.existingUploadedBod
const zip = state => state.zip
const renewableUploadedFiles = state => state.renewableUploadedFiles
const renewableUploadedBranchAmla = state => state.renewableUploadedBranchAmla
const renewableUploadedBranch = state => state.renewableUploadedBranch
const branch_business_permit = state => state.branch_business_permit
const branchDialog = state => state.branchDialog
const fla_amla = state => state.fla_amla
const amlaDialog = state => state.amlaDialog
const renewableUploadedOwnerAmla = state => state.renewableUploadedOwnerAmla
const ownerAmlaDialog = state => state.ownerAmlaDialog
const owner_amla = state => state.owner_amla
const final_comment = state => state.final_comment
const setFinalComments = state => state.setFinalComments
const documentMaintenance = state => state.documentMaintenance
const documents_maintenance = state => state.documents_maintenance
const witness = state => state.witness
const existingWitnessUploaded = state => state.existingWitnessUploaded
const signatory = state => state.signatory
const existingSignatoryUploaded = state => state.existingSignatoryUploaded
const province = state => state.province
const businessAddCity = state => state.businessAddCity
const businessAddRegion = state => state.businessAddRegion
const businessAddZip = state => state.businessAddZip
const mailingBusinessAddCity = state => state.mailingBusinessAddCity
const mailingBusinessAddZip = state => state.mailingBusinessAddZip
const primaryContactCity = state => state.primaryContactCity
const primaryContactZip = state => state.primaryContactZip
const complianceCity = state => state.complianceCity
const complianceZip = state => state.complianceZip
const branchesCity = state => state.branchesCity
const branchesZip = state => state.branchesZip
const existingBranchesCity = state => state.existingBranchesCity
const existingBranchesCityMailing = state => state.existingBranchesCityMailing
const existingBranchesZip = state => state.existingBranchesZip
const existingBranchesZipMailing = state => state.existingBranchesZipMailing
const ownersCity = state => state.ownersCity
const branchMailingZip = state => state.branchMailingZip
const branchMailingCity = state => state.branchMailingCity
const ownersZip = state => state.ownersZip
const existingOwnersCity = state => state.existingOwnersCity
const existingOwnersZip = state => state.existingOwnersZip
const bodCity = state => state.bodCity
const bodZip = state => state.bodZip
const existingBodCity = state => state.existingBodCity
const existingBodZip = state => state.existingBodZip
const returnedEvaComments = state => state.returnedEvaComments
const disapproveComments = state => state.disapproveComments
const compliance_returned_comments = state => state.compliance_returned_comments
const encoder_returned_comments = state => state.encoder_returned_comments
const compliance_approved_comments = state => state.compliance_approved_comments
const logsDialog = state => state.logsDialog
const sasDetails = state => state.sasDetails
const clusterDetails = state => state.clusterDetails
const users = state => state.users
const user_email = state => state.user_email
const primaryDetailsRadio1 = state => state.primaryDetailsRadio1
const primaryDetailsRadio2 = state => state.primaryDetailsRadio2
const avp_details = state => state.avp_details
const mother_entity_details = state => state.mother_entity_details


export default {
	spNumber,
	salesProspectingForm,
	documents,
	selectedSalesProspectingForm,
	branches,
	moa,
	existingUploadedFiles,
	owners,
	countries,
	cities,
	primaryBusinessAddress,
	complianceOfficer,
	primaryContactInfo,
	otherPrimaryDetails,
	primaryDetails,
	existingUploadedOwners,
	existingInitialUploaded,
	initialUploaded,
	genericRule,
	checkboxRule,
	scoreCardRule,
	existingUploadedBranches,
	emailRule,
	fileRule,
	comment,
	setComments,
	bsp,
	existingBSP,
	salesProspectingFormExisting,
	bspRule,
	existingGenericRule,
	existingEmailRule,
	existingFileRUle,
	single_proprietor_upload,
	bod,
	existingUploadedBod,
	zip,
	renewableUploadedFiles,
	renewableUploadedBranchAmla,
	renewableUploadedBranch,
	branch_business_permit,
	branchDialog,
	fla_amla,
	amlaDialog,
	renewableUploadedOwnerAmla,
	ownerAmlaDialog,
	owner_amla,
	final_comment,
	setFinalComments,
	documentMaintenance,
	documents_maintenance,
	witness,
	existingWitnessUploaded,
	signatory,
	existingSignatoryUploaded,
	province,
	businessAddCity,
	businessAddRegion,
	businessAddZip,
	mailingBusinessAddCity,
	mailingBusinessAddZip,
	primaryContactCity,
	primaryContactZip,
	complianceCity,
	complianceZip,
	branchesCity,
	branchesZip,
	branchMailingZip,
	existingBranchesCity,
	existingBranchesZip,
	ownersCity,
	ownersZip,
	branchMailingCity,
	existingOwnersCity,
	existingOwnersZip,
	bodCity,
	bodZip,
	existingBodCity,
	existingBodZip,
	returnedEvaComments,
	disapproveComments,
	compliance_returned_comments,
	compliance_approved_comments,
	logsDialog,
	encoder_returned_comments,
	sasDetails,
	clusterDetails,
	users,
	user_email,
	primaryDetailsRadio1,
	primaryDetailsRadio2,
	avp_details,
	existingBranchesCityMailing,
	existingBranchesZipMailing,
	mother_entity_details
	
};