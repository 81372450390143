import Repository from '../Repository';
// import axios from 'axios';

export default {
	getListEncoder () {
        return Repository.get('/api/merchant/encoder-merchant')
    },
	submitToOnboarding (payload) {
        return Repository.get('/api/merchant/send/' + payload)
    },
	checkSp (payload) {
        return Repository.get('/api/merchant/check/' + payload)
    },
    getListOnboarding () {
        return Repository.get('/api/merchant/onboarding-merchant')
    },
    uploadExcel ({payload}) {
        return Repository.post('/api/merchant/import/' + payload.id , payload.payload)
    },
    getFlaList (payload) {
        return Repository.get('/api/merchant/fla-merchant/' + payload)
    },
    submitMerchantEmail ({payload}) {
        return Repository.post('/api/merchant/fla-email/' + payload.id , payload.finalPayload)
    },
};