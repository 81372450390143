import Repository from '../Repository';
import axios from 'axios';

export default {
	searchUsers () {
		return Repository.get('/api/admin/user');
	},
	// allHrisUsers () {
	// 	return Repository.get(process.env.VUE_APP_HRIS_BASE_URL + '/api/employee/basicinfo/status/1');
	// },

	allHrisUsers(token) {
        return axios.get(process.env.VUE_APP_HRIS_BASE_URL + '/api/employee/basicinfo/status/1', {
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        });
    },
	usertoSam (payload) {
		return Repository.post('/api/admin/user/add' , payload);
	},
	toggleStatus (payload) {
		return Repository.put('/api/admin/status/' + payload);
	},
	updateRole (payload) {
		return  Repository.put('/api/admin/role/' + payload.id, payload);
	},
	addToOnboardingCommittee (payload) {
		return  Repository.post('api/admin/sp-onboarding-committee/', payload);
	},
	addToEvaluationCommittee (payload) {
		return  Repository.post('/api/admin/sp-evaluation-committee/', payload);
	},
	addToAvpEncoder (payload) {
		return  Repository.post('/api/admin/sp-avp-encoder/', payload);
	},
	addAsPresident (payload) {
		return  Repository.post('/api/admin/sp-final-approver', payload);
	},
	addAsValidation (payload) {
		return  Repository.post('/api/admin/sp-validation-committee/', payload);
	},
	addAsDetEncoder (payload) {
		return  Repository.post('/api/admin/det-encoder/', payload);
	},
	addAsCompliance (payload) {
		return  Repository.post('/api/admin/det-compliance-approver/', payload);
	},
	addAsControlCommittee (payload) {
		return  Repository.post('/api/admin/control-committee/', payload);
	},
	addAsSasCommittee (payload) {
		return  Repository.post('/api/admin/sas-committee/', payload);
	},
	removeAsValidation (payload) {
		return  Repository.delete('/api/admin/sp-validation-committee/' + payload);
	},
	removeAsCompliance (payload) {
		return  Repository.delete('/api/admin/det-compliance-approver/' + payload);
	},
	removeAsControlCommittee (payload) {
		return  Repository.delete('/api/admin/control-committee/' + payload);
	},
	removeAsSasCommittee (payload) {
		return  Repository.delete('/api/admin/sas-committee/' + payload);
	},
	removeDetEncoder (payload) {
		return  Repository.delete('/api/admin/det-encoder/' + payload);
	},
	removeAsPresident (payload) {
		return  Repository.delete('/api/admin/sp-final-approver/' + payload);
	},
	removeFromOnboardingCommittee (payload) {
		return  Repository.delete('api/admin/sp-onboarding-committee/' + payload);
	},
	removeFromEvaluationCommittee (payload) {
		return  Repository.delete('/api/admin/sp-evaluation-committee/' + payload);
	},
	removeFromAvpEncoder (payload) {
		return  Repository.delete('/api/admin/sp-avp-encoder/' + payload);
	},
	addAsOhHead (payload) {
		return  Repository.post('/api/admin/oh-approver/', payload);
	},
	removeAsOhHead (payload) {
		return  Repository.delete('/api/admin/oh-approver/' + payload);
	},
	addAsFinanceCommittee (payload) {
		return  Repository.post('/api/admin/finance-committee/', payload);
	},
	removeFinanceCommittee (payload) {
		return  Repository.delete('/api/admin/finance-committee/' + payload);
	},
};