import Repository from '../Repository';
// import axios from 'axios';

export default {
	getMailingAddress () {
        return Repository.get('/api/export/sales-prospect/mailing-address/overall')
    },
    getMailingAddressCluster (payload) {
        return Repository.get('/api/export/sales-prospect/mailing-address/' + payload)
    },
    getStatusOverall () {
        return Repository.get('/api/export/sales-prospect/summary-status/overall')
    },
    getStatusCluster (payload) {
        return Repository.get('/api/export/sales-prospect/summary-status/' + payload)
    },
    getPrpTypeOverall () {
        return Repository.get('/api/export/sales-prospect/prp-type/overall')
    },
    gettPrpTypeCluster (payload) {
        return Repository.get('api/export/sales-prospect/prp-type/' + payload)
    },
    getCompletionRateOverall () {
        return Repository.get('/api/export/sales-prospect/completion-rate/overall')
    },
    getCompletionRateCluster (payload) {
        return Repository.get('api/export/sales-prospect/completion-rate/' + payload)
    },
    getBusinessPartnerOverall () {
        return Repository.get('/api/export/sales-prospect/prp-area/overall')
    },
    getPrpDirectoryOverall () {
        return Repository.get('/api/export/sales-prospect/prp-directory/overall')
    },
    getBusinessPartnerCluster (payload) {
        return Repository.get('api/export/sales-prospect/prp-area/' + payload)
    },
    getPrpDirectoryCluster (payload) {
        return Repository.get('api/export/sales-prospect/prp-directory/' + payload)
    },
    getReports () {
		return Repository.get('/api/export/sales-prospect/reports');
	},
    getMasterfileOverall () {
        return Repository.get('/api/export/sales-prospect/overall');
    },
    getMasterfileCluster (payload) {
        return Repository.get('/api/export/sales-prospect/' + payload)
    },
    getSosEntityTemplate () {
        return Repository.get('/api/export/sales-prospect/template/entity-sos')
    },
    getSosLocationTemplate () {
        return Repository.get('/api/export/sales-prospect/template/location-sos')
    },    
    getMerchantWuTemplate () {
        return Repository.get('/api/export/sales-prospect/template/merchant-wu')
    },
    getEntityIsTemplate () {
        return Repository.get('/api/export/sales-prospect/template/entity-is-import')
    },
    getLocationIsTemplate () {
        return Repository.get('/api/export/sales-prospect/template/location-is-import')
    },
    getLocationPrpTemplate () {
        return Repository.get('/api/export/sales-prospect/template/location-prp-import')
    },
    getUserReports () {
        return Repository.get('/api/export/sales-prospect/user-reports')
    },
    getIsEntityReport () {
        return Repository.get('/api/export/is/entity-export')
    },
    getIsLocationReport () {
        return Repository.get('/api/export/is/location-export')
    },
    getChargesFinance () {
        return Repository.get('/api/export/sales-prospect/template/charges')
    },
    getCommissionTrailFinance () {
        return Repository.get('/api/export/sales-prospect/template/commission-trail')
    },
    getCommissionFinance () {
        return Repository.get('/api/export/sales-prospect/template/commission')
    },
    getDefBankAccFinance () {
        return Repository.get('/api/export/sales-prospect/template/bank-accounts')
    },
    getEmailFinance () {
        return Repository.get('/api/export/sales-prospect/template/email')
    },
    getManpowerFinance () {
        return Repository.get('/api/export/sales-prospect/template/manpower')
    },
};