import Repository from '../Repository';
// import axios from 'axios';

export default {
	updateFla ({payload}) {
		return Repository.post('/api/bsp/update-fla/' + payload.id, payload.finayPayload);
	},
	createBsp (payload) {
		return Repository.get('/api/bsp/add-det/' + payload);
	},
	getDetBySp (payload) {
		return Repository.get('/api/bsp/get-by-sp/' + payload);
	},
	addDetDetails ({payload}) {
		return Repository.post('/api/bsp/send-det/' + payload.id, payload.finalPayload);
	},
	getDetDetails ({payload}) {
		return Repository.post('/api/bsp/get-by-bsp/' + payload.id, payload.finalPayload);
	},
	getAllBsp(){
		return Repository.get('/api/bsp/get-all/')
	},
	uploadDetDocuments ({payload}) {
		return Repository.post('/api/bsp/upload/' + payload.id, payload.payload)
	},
	getByBspId({payload}){
		return Repository.post('/api/bsp/get-by-bsp/'+ payload.id, payload.finalPayload)
	},
	sendToCompliance({payload}){
		return Repository.post('/api/bsp/send-approval/'+ payload.id, payload.finalPayload)
	},
	returnDet({payload}){
		return Repository.put('/api/bsp/return-det/'+ payload.id, payload.finalPayload)
	},
	approveDet({payload}){
		return Repository.put('/api/bsp/approve-det/'+ payload.id, payload.finalPayload)
	},
	returnToEncoder({payload}){
		return Repository.put('/api/bsp/det-encoder/return-det/'+ payload.id, payload.finalPayload)
	},
	updateStatus({payload}){
		return Repository.post('/api/bsp/update-status/'+ payload.id, payload.finalPayload)
	},
	uploadComplianceDocuments ({payload}) {
		return Repository.post('/api/bsp/upload/email-trigger/' + payload.id, payload.payload)
	},
	getSalesProspecting (payload) {
		return Repository.get('/api/bsp/det-branch-fla/' + payload)
	},
	pageBranch ({payload}) {
		return Repository.get('/api/bsp/det-branch-fla/' + payload.id + '?page=' + payload.page)
	},
};