export default{
    disable_encoder_view: false,
    disable_det_view: false,
    bspId: '',
    detDetails: {
        is_draft: false,
        citizenship: 'Filipino',
        tin_no: '',
        fax_no: '',
        det_type: '',
        ownership_type: ''
    },
    comment: '',
    existingDetFiles: [],
    detFilesUpload : {
        bsp_id: '',
        fss_02_001: {
            file: null
        },
        det_fss_03_001: {
            file: null
        },
        fss_03_003: {
            file: null
        },
        fss_03_005: {
            file: null
        },
        det_form_03_009: {
            file: null
        },
        det_form_2m: {
            file: null
        },
        det_form_3m: {
            file: null
        },
        form_3m_1: {
            file: null
        },
        form_3m_2: {
            file: null
        },
        form_3m_3: {
            file: null
        },
        fss_03_002: {
            file: null
        },
        det_form_4m: {
            file: null
        },
        det_form_5m: {
            file: null
        },
        det_form_6m: {
            file: null
        },
        det_form_7m: {
            file: null
        },
        det_form_8m: {
            file: null
        },
        det_form_9m: {
            file: null
        },
        det_form_10m: {
            file: null
        },
        letter_of_intent: {
            file: null
        },
        proof_of_payment: {
            file: null
        },
        letter_informing: {
            file: null
        },
        notarized_statement: {
            file: null
        },
        bsp_cor: {
            file: null
        },
        bsp_rmp: {
            file: null
        },
        payment_bsp_assessment: {
            file: null
        },
        vendee_transferee: {
            file: null
        },
        contract_change_ownership: {
            file: null
        },
        proof_payment_filing_fee: {
            file: null
        },
        notarized_det_3m: {
            file: null
        },
        contract_agreement: {
            file: null
        },
        nbi_clearance: {
            file: null
        },
        proof_attendance: {
            file: null
        },
        schedule_ownership: {
            file: null
        },
        death_certificate: {
            file: null
        },
        affidavit_release: {
            file: null
        },
        compliance_cor_1: {
            file: null
        },
        business_license: {
            file: null
        },
        amlcs: {
            file: null
        },
        mother_of_contract: {
            file: null
        }
    },
    compliance: {
        detStatus: ''
    },
    complianceUpload: {
        norr_fsd9: {
            file: null
        },
        norr_fss_mail: {
            file: null
        },
        oprn_invoice: {
            file: null
        },
        oprn_receipt: {
            file: null
        },
        settle_oprn_payment: {
            file: null
        },
        accreditation_letter: {
            file: null
        },
        is_process_bsp: true
    },
    existingComplianceDocs: []
   
}