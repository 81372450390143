/* eslint-disable */

import Vue from "vue";
import Router from "vue-router";
import store from "./store/index";



Vue.use(Router);

const router = new Router({
  routes: [
    {
      path: "/dashboard",
      name: "dashboard",
      component: () => import("./views/Dashboard.vue"),
      meta: { requiresAuth: true, breadcrumb: [{ text: "Dashboard", disabled: false }] }
    },
    {
      path: "/sales-prospecting",
      name: "sales prospecting",
      component: () => import("./views/SalesProspectingIndex.vue"),
      meta: { requiresAuth: true, breadcrumb: [{ text: "New Sales Prospect", disabled: false }] }
    },
    {
      path: "/existing-sales-prospecting",
      name: "existing sales prospecting",
      component: () => import("./views/ExistingSalesProspectingIndex.vue"),
      meta: { requiresAuth: true, breadcrumb: [{ text: "Existing Sales Prospect", disabled: false }] }
    },
    {
      path: "/sales-prospecting-eval",
      name: "Sales Prospecting Approval",
      component: () => import("./views/EvalApprovalIndex.vue"),
      meta: { requiresAuth: true, breadcrumb: [{ text: "Sales Prospecting Approval", disabled: false }] }
    },
    {
      path: "/all-sales-prospect",
      name: "all sales prospect",
      component: () => import("./views/AllSalesProspect.vue"),
      meta: { requiresAuth: true, breadcrumb: [{ text: "Sales Prospect List", disabled: false }] }
    },
    {
      path: "/revert-status",
      name: "Revert Status",
      component: () => import("./views/StatusRevert.vue"),
      meta: { requiresAuth: true, breadcrumb: [{ text: "Revert Status", disabled: false }] }
    },
    {
      path: "/sas-table",
      name: "sales prospect",
      component: () => import("./views/SasIndex.vue"),
      meta: { requiresAuth: true, breadcrumb: [{ text: "Sales Prospect List", disabled: false }] }
    },
    {
      path: "/clustering",
      name: "Clustering",
      component: () => import("./views/ClusteringView.vue"),
      meta: { requiresAuth: true, breadcrumb: [{ text: "Clustering", disabled: false }] }
    },
    {
      path: "/sp-termination-list",
      name: "termination list",
      component: () => import("./views/SPTermination.vue"),
      meta: { requiresAuth: true, breadcrumb: [{ text: "Termination List", disabled: false }] }
    },
    {
      path: "/sales-prospecting/update-initial/:id",
      name: "sales prospecting update initial",
      props: true,
      component: () => import("./views/SalesProspectingUpdateInitial.vue"),
      meta: { requiresAuth: true, breadcrumb: [{ text: "Sales Prospecting Home", disabled: false }] }
    },

    {
      path: "/sales-prospecting/existing-update-initial/:id",
      name: "sales prospecting update initial",
      props: true,
      component: () => import("./views/ExistingSPUpdateInitial.vue"),
      meta: { requiresAuth: true, breadcrumb: [{ text: "Sales Prospecting Home", disabled: false }] }
    },
    
    {
      path: "/sales-prospecting/final-view-only/:id",
      name: "New Sales Prospecting",
      props: true,
      component: () => import("./views/ViewSalesProspectingUpdateDisabled.vue"),
      meta: { requiresAuth: true, breadcrumb: [{ text: "Sales Prospecting Home", disabled: false, href: "javascript:history.back()" },{ text: "Sales Prospecting", disabled: false }] }
    },


    {
      path: "/sales-prospecting/new/:existing",
      name: "New Sales Prospecting",
      props: true,
      component: () => import("./views/SalesProspecting.vue"),
      meta: { requiresAuth: true, breadcrumb: [{ text: "Sales Prospecting Home", disabled: false, href: "javascript:history.back()" },{ text: "New Sales Prospecting", disabled: false }] }
    },
    {
      path: "/sales-prospecting/existing/",
      name: "New Sales Prospecting",
      props: true,
      component: () => import("./views/ExistingSalesProspecting.vue"),
      meta: { requiresAuth: true, breadcrumb: [{ text: "Sales Prospecting Home", disabled: false, href: "javascript:history.back()" },{ text: "Existing Sales Prospecting", disabled: false }] }
    },




    {
      path: "/sales-prospecting/initial-view-only/:id",
      name: "New Sales Prospecting",
      props: true,
      component: () => import("./views/ViewSalesProspectingDisabled.vue"),
      meta: { requiresAuth: true, breadcrumb: [{ text: "Sales Prospecting Home", disabled: false, href: "javascript:history.back()" },{ text: "New Sales Prospecting", disabled: false }] }
    },
    {
      path: "/sales-prospecting/eval-approver/:id",
      name: "Sales Prospecting Evaluation Approval",
      component: () => import("./views/ViewSalesProspectingDisabled.vue"),
      props: true,
      meta: { requiresAuth: true, breadcrumb: [{ text: "Sales Prospecting Home", disabled: false, href: "javascript:history.back()" },{ text: "Sales Prospecting Approval", disabled: false }] }
    },
    {
      path: "/sales-prospecting/onboarding-approver/:id",
      name: "Sales Prospecting Onboarding Approval",
      component: () => import("./views/ViewSalesProspectingUpdateDisabled.vue"),
      props: true,
      meta: { requiresAuth: true, breadcrumb: [{ text: "Sales Prospecting Home", disabled: false, href: "javascript:history.back()" },{ text: "Sales Prospecting Approval - Onboarding Committee", disabled: false }] }
    },
    {
      path: "/sales-prospecting/validation-approver/:id",
      name: "Sales Prospecting Onboarding Approval",
      component: () => import("./views/ViewSalesProspectingUpdateDisabled.vue"),
      props: true,
      meta: { requiresAuth: true, breadcrumb: [{ text: "Sales Prospecting Home", disabled: false, href: "javascript:history.back()" },{ text: "Sales Prospecting Approval - Onboarding Committee", disabled: false }] }
    },
    {
      path: "/sales-prospecting/view-profile/:id",
      name: "View Profile",
      component: () => import("./views/Profile.vue"),
      props: true,
      meta: { requiresAuth: true, breadcrumb: [{ text: "Sales Prospecting Home", disabled: false, href: "javascript:history.back()" },{ text: "View Profile", disabled: false }] }
    },
    {
      path: "/sales-prospecting/update/:id",
      name: "Update Sales Prospecting",
      component: () => import("./views/SalesProspectingUpdate.vue"),
      props: true,
      meta: { requiresAuth: true, breadcrumb: [{ text: "Sales Prospecting Home", disabled: false, href: "javascript:history.back()" },{ text: "Update Sales Prospecting", disabled: false }] }
    },
    {
      path: "/users",
      name: "User Management",
      component: () => import("./views/Users.vue"),
      meta: { requiresAuth: true, breadcrumb: [{ text: "User Roles", disabled: false }] }
    },
    {
      path: "/add-user",
      name: "User Management",
      component: () => import("./views/AddUser.vue"),
      meta: { requiresAuth: true, breadcrumb: [{ text: "Add User", disabled: false }] }
    },
    {
      path: "/score-card-maintenance",
      name: "Score Card Maintenance",
      component: () => import("./views/ScoreCardMaintenance.vue"),
      meta: { requiresAuth: true, breadcrumb: [{ text: "Score Card Maintenance", disabled: false }] }
    },
    {
      path: "/mail-recipients",
      name: "Mail Receipients Maintenance",
      component: () => import("./views/MailRecipients.vue"),
      meta: { requiresAuth: true, breadcrumb: [{ text: "Email Maintenance", disabled: false }] }
    },
    {
      path: "/document-maintenance",
      name: "Mail Receipients Maintenance",
      component: () => import("./views/DocumentMaintenance.vue"),
      meta: { requiresAuth: true, breadcrumb: [{ text: "Document Maintenance", disabled: false }] }
    },
    {
      path: "/faq-maintenance",
      name: "FAQ Maintenance",
      component: () => import("./views/FaqMaintenance.vue"),
      meta: { requiresAuth: true, breadcrumb: [{ text: "FAQ Maintenance", disabled: false }] }
    },
    {
      path: "/status-maintenance",
      name: "Status Maintenance",
      component: () => import("./views/StatusMaintenance.vue"),
      meta: { requiresAuth: true, breadcrumb: [{ text: "Status Maintenance", disabled: false }] }
    },
    {
      path: "/acronym-maintenance",
      name: "Acronym Maintenance",
      component: () => import("./views/AcronymMaintenance.vue"),
      meta: { requiresAuth: true, breadcrumb: [{ text: "Acronym Maintenance", disabled: false }] }
    },
    {
      path: "/reports",
      name: "Reports",
      component: () => import("./views/Reports/ReportIndex.vue"),
      meta: { requiresAuth: true, breadcrumb: [{ text: "Reports", disabled: false }] }
    },

    // BSP LINKS
    {
      path: "/bsp",
      name: "DET",
      component: () => import("./views/BSP/BspIndex.vue"),
      meta: { requiresAuth: true, breadcrumb: [{ text: "BSP", disabled: false }] }
    },
    {
      path: "/bsp-approver",
      name: "BSP",
      component: () => import("./views/BSP/BspApproverIndex.vue"),
      meta: { requiresAuth: true, breadcrumb: [{ text: "BSP", disabled: false }] }
    },
    {
      path: "/bsp-encoder",
      name: "BSP",
      component: () => import("./views/BSP/BspEncoderIndex.vue"),
      meta: { requiresAuth: true, breadcrumb: [{ text: "BSP", disabled: false }] }
    },
    {
      path: "/det-form/:id",
      name: "DET Form",
      component: () => import("./views/BSP/BspDetForm.vue"),
      meta: { requiresAuth: true, breadcrumb: [{ text: "BSP Home", disabled: false, href: "javascript:history.back()" },{ text: "DET", disabled: false }] }
    },
    {
      path: "/det-form-view/:id",
      name: "DET Form",
      component: () => import("./views/BSP/BspDetFormView.vue"),
      meta: { requiresAuth: true, breadcrumb: [{ text: "BSP Home", disabled: false, href: "javascript:history.back()" },{ text: "DET", disabled: false }] }
    },
    {
      path: "/is-primary",
      name: "Information System",
      component: () => import("./views/InformationSystem/IsIndex.vue"),
      meta: { requiresAuth: true, breadcrumb: [{ text: "Information System", disabled: false }] }
    },
    {
      path: "/is-standalone",
      name: "Information System",
      component: () => import("./views/InformationSystem/IsStandAloneIndex.vue"),
      meta: { requiresAuth: true, breadcrumb: [{ text: "Information System", disabled: false }] }
    },
    {
      path: "/is-initial/:id",
      name: "Information System",
      component: () => import("./views/InformationSystem/IsInitial.vue"),
      meta: { requiresAuth: true, breadcrumb: [{ text: "Information System Table", disabled: false, href: "javascript:history.back()" },{ text: "Information System", disabled: false }] }
    },
    {
      path: "/is-final",
      name: "Information System",
      component: () => import("./views/InformationSystem/IsFinalIndex.vue"),
      meta: { requiresAuth: true, breadcrumb: [{ text: "Information System", disabled: false }] }
    },
    {
      path: "/profiling",
      name: "Profiling",
      component: () => import("./views/Profiling/ProfilingIndex.vue"),
      meta: { requiresAuth: true, breadcrumb: [{ text: "Profiling", disabled: false }] }
    },

    {
      path: "/merchant-initial",
      name: "Merchant Enrollment",
      component: () => import("./views/MerchantEnrollment/MerchantIndexEncoder.vue"),
      meta: { requiresAuth: true, breadcrumb: [{ text: "Merchant Enrollment", disabled: false }] }
    },
    {
      path: "/merchant-final",
      name: "Merchant Enrollment",
      component: () => import("./views/MerchantEnrollment/MerchantIndexOnboarding.vue"),
      meta: { requiresAuth: true, breadcrumb: [{ text: "Merchant Enrollment", disabled: false }] }
    },
    {
      path: "/merchant-enrollment/:id",
      name: "Merchant Enrollment",
      component: () => import("./views/MerchantEnrollment/MerchantDetails.vue"),
      meta: { requiresAuth: true, breadcrumb: [{ text: "Merchant Enrollment", disabled: false, href: "javascript:history.back()" },{ text: "Merchant Enrollment Details", disabled: false }] }
    },
    {
      path: "/encoder-suspension",
      name: "Account Suspension",
      component: () => import("./views/AccountManagement/Encoder/SuspensionEncoder.vue"),
      meta: { requiresAuth: true, breadcrumb: [{ text: "Account Suspension", disabled: false }] }
    },
    {
      path: "/encoder-deactivation",
      name: "Account Deactivation",
      component: () => import("./views/AccountManagement/Encoder/DeactivationEncoder.vue"),
      meta: { requiresAuth: true, breadcrumb: [{ text: "Account Deactivation", disabled: false }] }
    },
    {
      path: "/encoder-reactivation",
      name: "Account Reactivation",
      component: () => import("./views/AccountManagement/Encoder/ReactivationEncoder.vue"),
      meta: { requiresAuth: true, breadcrumb: [{ text: "Account Reactivation", disabled: false }] }
    },
    {
      path: "/onboarding-suspension",
      name: "Account Suspension",
      component: () => import("./views/AccountManagement/Onboarding/SuspensionOnboarding.vue"),
      meta: { requiresAuth: true, breadcrumb: [{ text: "Account Suspension", disabled: false }] }
    },
    {
      path: "/onboarding-suspension-approval",
      name: "Account Suspension",
      component: () => import("./views/AccountManagement/Onboarding/SuspensionApprovalOnboarding.vue"),
      meta: { requiresAuth: true, breadcrumb: [{ text: "Account Suspension", disabled: false }] }
    },
    {
      path: "/onboarding-deactivation",
      name: "Account Deactivation",
      component: () => import("./views/AccountManagement/Onboarding/DeactivationOnboarding.vue"),
      meta: { requiresAuth: true, breadcrumb: [{ text: "Account Deactivation", disabled: false }] }
    },
    {
      path: "/onboarding-reactivation",
      name: "Account Reactivation",
      component: () => import("./views/AccountManagement/Onboarding/ReactivationOnboarding.vue"),
      meta: { requiresAuth: true, breadcrumb: [{ text: "Account Reactivation", disabled: false }] }
    },
    {
      path: "/onboarding-head-suspension",
      name: "Account Suspension",
      component: () => import("./views/AccountManagement/OnboardingHead/OhSuspensionApproval.vue"),
      meta: { requiresAuth: true, breadcrumb: [{ text: "Account Suspension", disabled: false }] }
    },
    {
      path: "/onboarding-head-deactivation",
      name: "Account Deactivation",
      component: () => import("./views/AccountManagement/OnboardingHead/OhDeactivationApproval.vue"),
      meta: { requiresAuth: true, breadcrumb: [{ text: "Account Deactivation", disabled: false }] }
    },
    {
      path: "/onboarding-head-reactivation",
      name: "Account Reactivation",
      component: () => import("./views/AccountManagement/OnboardingHead/OhActivationApproval.vue"),
      meta: { requiresAuth: true, breadcrumb: [{ text: "Account Reactivation", disabled: false }] }
    },
    {
      path: "/archived-list",
      name: "Archived List",
      component: () => import("./views/AccountManagement/ArchivedList.vue"),
      meta: { requiresAuth: true, breadcrumb: [{ text: "Archived List", disabled: false }] }
    },
    {
      path: "/finance-encoder",
      name: "Finance",
      component: () => import("./views/Finance/Encoder/EncoderTable.vue"),
      meta: { requiresAuth: true, breadcrumb: [{ text: "Finance", disabled: false }] }
    },
    {
      path: "/finance-onboarding",
      name: "Finance",
      component: () => import("./views/Finance/Onboarding/OnboardingTable.vue"),
      meta: { requiresAuth: true, breadcrumb: [{ text: "Finance", disabled: false }] }
    },
    {
      path: "/finance-banks",
      name: "Finance",
      component: () => import("./views/Finance/Onboarding/OnboardingBankEdit.vue"),
      meta: { requiresAuth: true, breadcrumb: [{ text: "Finance", disabled: false }] }
    },
    {
      path: "/finance-onboarding-head",
      name: "Finance",
      component: () => import("./views/Finance/OnboardingHead/OnboardingHeadTable.vue"),
      meta: { requiresAuth: true, breadcrumb: [{ text: "Finance", disabled: false }] }
    },
    {
      path: "/finance/:id",
      name: "Finance",
      props: true,
      component: () => import("./views/Finance/FinanceIndex.vue"),
      meta: { requiresAuth: true, breadcrumb: [{ text: "Finance List", disabled: false, href: "javascript:history.back()" },{ text: "Finance", disabled: false }] }
    },
    {
      path: "/finance-imports",
      name: "Finance Imports",
      props: true,
      component: () => import("./views/Finance/Admin/FinanceImports.vue"),
      meta: { requiresAuth: true, breadcrumb: [{ text: "Finance", disabled: false }] }
    },
    {
      path: "/finance-committee",
      name: "Finance",
      props: true,
      component: () => import("./views/Finance/FinanceCommittee/FinanceComIndex.vue"),
      meta: { requiresAuth: true, breadcrumb: [{ text: "Finance", disabled: false }] }
    },
  ],
  mode: "history"
  // base: process.env.BASE_URL,
});

// router.beforeEach((to, from, next) => {
//   if (to.matched.some(record => record.meta.requiresAuth)) {
//     let loggedin = JSON.parse(localStorage.getItem("auth-data"));
//     console.log(loggedin, 'loggedin ')
//     //let loggedin = Vue.$cookies.get("auth-data");
//     // console.log(Vue.$cookies.get("auth-data"))
//     if (loggedin) {
//       store.dispatch("auth/setCurrUser", loggedin.user_details);
//       next();
//     } //else window.location = "login";
//   } else {
//     next();
//   }
// });

export default router;
