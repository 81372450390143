import { RepositoryFactory } from '../../../api_repo/RepositoryFactory';
import router from '../../../router';
import Vue from 'vue';
// import store from '../index'

const authRepository = RepositoryFactory.get('auth');

const setLoginOkta = (context,payload) => {
	context.commit('AUTH_REQUEST_OKTA');
	setTimeout(() => {
		localStorage.setItem('auth-data',JSON.stringify(payload));
		Vue.$cookies.set('auth-data', payload);
		context.commit('AUTH_SUCCESS_OKTA', payload);
		router.push('/dashboard');
	}, 2000);
};

// const doLoginDev = ({commit}, payload) => new Promise((resolve,reject) => {
// 	commit('app/SET_IS_LOADING',true,{ root: true });
// 	setTimeout(() => {
// 		authRepository.loginDev(payload)
// 			.then((response) => {
// 				commit('app/SET_IS_LOADING',false,{ root: true });
// 				commit('app/SET_SNACK_BAR',{'status':true,'message': 'Successfully Logged In! ','messagetype':'success'},{ root: true });
// 				resolve(response);
// 				context.commit('SET_RUN_SESSION', true)
// 				const currentTime = new Date()
// 				const expireTime = new Date(currentTime.getTime() + (45 * 60 * 1000))
				
// 				context.commit('SET_AUTH_TIMESTAMP', {
// 					timeLoggedIn: currentTime,
// 					timeToExpire: expireTime
// 				})	  
// 			})
// 			.catch((err) => {
// 				commit('app/SET_IS_LOADING',false,{ root: true });
// 				reject(err);
// 			});
// 	}, 2000);
// });

const doLoginDev = (context, payload) => new Promise((resolve, reject) => {
	context.commit('app/SET_IS_LOADING',true,{ root: true })
	setTimeout(() => {
		authRepository.loginDev(payload)
			.then((response) => {
				context.commit('app/SET_IS_LOADING',false,{ root: true });
				resolve(response);
				context.commit('SET_RUN_SESSION', true)
				const currentTime = new Date()
				const expireTime = new Date(currentTime.getTime() + (45 * 60 * 1000))
				
				context.commit('SET_AUTH_TIMESTAMP', {
					timeLoggedIn: currentTime,
					timeToExpire: expireTime
				})	  
			})
			.catch((err) => {
				console.log(err)
				reject(err.response);
			});
	}, 2000);
});

const doLogout = ({commit}, payload) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	// const newWindow = openWindow('', 'message');
	setTimeout(() => {
		authRepository.logout(payload)
			.then((response) => {
				console.log('Actions Response')
				console.log(response)
				// const urlAccountSelection = response.data.result;
				// newWindow.location.href = urlAccountSelection
				localStorage.clear();
				Vue.$cookies.remove('auth-data');
				commit('app/SET_IS_LOADING',false,{ root: true });
				commit('app/SET_SNACK_BAR',{'status':true,'message': 'Successfully Logged Out! ','messagetype':'success'},{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doGoogleLogout = ({commit}) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		authRepository.googleLogout()
			.then((response) => {
				console.log('Actions Response')
				console.log(response)
				localStorage.clear();
				Vue.$cookies.remove('auth-data');
				commit('app/SET_IS_LOADING',false,{ root: true });
				commit('app/SET_SNACK_BAR',{'status':true,'message': 'Successfully Logged Out! ','messagetype':'success'},{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doLoginWithOkta = (context) => new Promise((resolve, reject) => {
	context.commit('AUTH_REQUEST_OKTA');
	const newWindow = openWindow('', 'message');
  
	setTimeout(() => {
		authRepository.loginOkta()
			.then((response) => {
				console.log('login here')
				console.log(response)
				const urlAccountSelection = response.data;
				newWindow.location.href = urlAccountSelection;
				resolve(response);
				context.commit('SET_RUN_SESSION', true)
				const currentTime = new Date()
				const expireTime = new Date(currentTime.getTime() + (45 * 60 * 1000))
				
				context.commit('SET_AUTH_TIMESTAMP', {
					timeLoggedIn: currentTime,
					timeToExpire: expireTime
				})	  
			})
			.catch((err) => {
				console.log(err)
				reject(err.response);
			});
	}, 2000);
});

const doLoginWithGoogle = (context) => new Promise((resolve, reject) => {
	context.commit('AUTH_REQUEST_OKTA');
	const newWindow = openWindow('', 'message');
	console.log('GOOGLE')
  
	setTimeout(() => {
		authRepository.loginGoogle()
			.then((response) => {
				console.log('login here')
				console.log(response)
				const urlAccountSelection = response.data;
				newWindow.location.href = urlAccountSelection;
				resolve(response);
				context.commit('SET_RUN_SESSION', true)
				const currentTime = new Date()
				const expireTime = new Date(currentTime.getTime() + (45 * 60 * 1000))
				
				context.commit('SET_AUTH_TIMESTAMP', {
					timeLoggedIn: currentTime,
					timeToExpire: expireTime
				})	  
			})
			.catch((err) => {
				console.log(err)
				reject(err.response);
			});
	}, 2000);
});


//copy this
function openWindow (url, title, options = {}) {
	if (typeof url === 'object') {
		options = url;
		url = '';
	}
  
	options = { url, title, width: 600, height: 720, ...options };
  
	const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screen.left;
	const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screen.top;
	const width = window.innerWidth || document.documentElement.clientWidth || window.screen.width;
	const height = window.innerHeight || document.documentElement.clientHeight || window.screen.height;
  
	options.left = ((width / 2) - (options.width / 2)) + dualScreenLeft;
	options.top = ((height / 2) - (options.height / 2)) + dualScreenTop;
  
	const optionsStr = Object.keys(options).reduce((acc, key) => {
		acc.push(`${key}=${options[key]}`);
		return acc;
	}, []).join(',');
  
	const newWindow = window.open(url, title, optionsStr);
  
	if (window.focus) {
		newWindow.focus();
	}
  
	return newWindow;
}

const setCurrUser = (context,payload) => {
	context.commit('SET_CURRENT_USER',payload);
};

const setUserID = (context, payload) => {
	context.commit('SET_USER_ID',payload);
};

const setPassword = (context, payload) => {
	context.commit('SET_PASSWORD',payload);
};

const authError = (context, payload) => {
	context.commit('AUTH_ERROR',payload);
};

const setIsAuthenticated = (context,payload) => {
	context.commit('SET_ISAUTHENTICATED',payload);
};

const doRefreshToken = ({commit}, payload) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		authRepository.refreshToken(payload)
			.then((response) => {
				console.log(response)
				commit('app/SET_IS_LOADING',false,{ root: true });
				commit('app/SET_SNACK_BAR',{'status':true,'message': 'Refresh Successful! ','messagetype':'success'},{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

const doGoogleRefreshToken = ({commit}, payload) => new Promise((resolve,reject) => {
	commit('app/SET_IS_LOADING',true,{ root: true });
	setTimeout(() => {
		authRepository.googleRefreshToken(payload)
			.then((response) => {
				console.log(response)
				commit('app/SET_IS_LOADING',false,{ root: true });
				commit('app/SET_SNACK_BAR',{'status':true,'message': 'Refresh Successful! ','messagetype':'success'},{ root: true });
				resolve(response);
			})
			.catch((err) => {
				commit('app/SET_IS_LOADING',false,{ root: true });
				reject(err);
			});
	}, 2000);
});

export default{
	doLogout,
	setUserID,
	setPassword,
	setCurrUser,
	authError,
	setIsAuthenticated,
	doLoginWithOkta,
	setLoginOkta,
	doRefreshToken,
	doLoginWithGoogle,
	doGoogleLogout,
	doGoogleRefreshToken,
	doLoginDev,
};